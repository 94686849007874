import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HttpService from "../../src/config/authAxios";
import toast from "react-hot-toast";

const MintingConfirmModal = ({ open, setOpen, setOpenKYC, clb }) => {
  const handleClose = () => setOpen(false);
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: user, error: userError } = getCurrentUserSelector;

  const [loading, setLoading] = useState(false);

  const mintClb = async () => {
    try {
      setLoading(true);

      const { data: result } = await HttpService.post("nft/details", {
        CLBId: clb?._id,
      });
      console.log(result);

      const { data } = await HttpService.post("nft/mint", {
        CLBId: clb?._id,
      });

      console.log(data);
      setOpen(false);
      toast.success("NFT Minted Successfully", {
        duration: 5000,
        position: "bottom-left",
      });
    } catch (error) {
      console.log(error);
      toast.error("Error while minting NFT", {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Card className="alertBox">
        <Stack direction="row" sx={{ my: 2 }}>
          <Typography align="center" variant="h5" sx={{ flex: 1 }}>
            Mint NFT !
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography>
              {" "}
              Do you want to transfer this NFT into your wallet now?
            </Typography>
          </Stack>
          <div className="w-full flex items-center gap-4 justify-end">
            <Button
              className="gradBtn2"
              onClick={() => {
                if (user?.country == "India") {
                  if (!user?.kycVerified) {
                    setOpenKYC(true);
                    toast.success(
                      "Please complete your KYC and connect your metamask wallet",
                      {
                        duration: 5000,
                        position: "bottom-left",
                      }
                    );
                    handleClose();
                  } else {
                    mintClb();
                  }
                  mintClb();
                } else {
                  mintClb();
                }
              }}
            >
              Yes
            </Button>
            <button className="btn btn-primary" onClick={handleClose}>
              No
            </button>
          </div>
        </Stack>
      </Card>
    </Modal>
  );
};

export default MintingConfirmModal;
