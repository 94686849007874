import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from "./blog.module.css";
import Header from "../../header/Header";
import { Box } from "@mui/material";
import BlogsImg from "../../../assets/png/blogImg1.png";
import { getBlog } from "../../../redux/blog/blog";
import HttpService from "../../../config/authAxios";
import { useSearchParams } from "react-router-dom";

const Blog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getBlogSelector = useSelector((state) => state?.blogs);
  const { result, loading } = getBlogSelector;
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(
    !searchParams.size ? "all" : searchParams.get("category")
  );

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      getCategories();
    } catch (error) {
      console.log("error blogs");
    }
  }, []);

  useEffect(() => {
    console.log("hee");
    dispatch(getBlog(category));
  }, [category]);

  useEffect(() => {
    !searchParams.size
      ? setCategory("all")
      : setCategory(searchParams.get("category"));
  }, [searchParams.get("category")]);

  const getCategories = async () => {
    try {
      const { data } = await HttpService.get("/blogsCategory/all");
      console.log(data);
      setCategories(data?.result);
    } catch (error) {
      console.log("error categories");
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Box className={Style.main}>
      <Header />
      <div className={Style.container}>
        <div className={Style.contained}>
          <div className={Style.head}>
            <div
              className={category === "all" ? Style.active : ""}
              onClick={() => setCategory("all")}
            >
              {/* <p>All</p> */}
              <p
                style={{
                  color: 'white',
                  backgroundColor: category === "all" ? 'transparent' : 'transparent',
                  outline: '0.5px solid #35ABAF',
                  padding: '8px 12px',
                  borderRadius: '18px',
                  textAlign: 'center',
                  height: '44px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0',

                }}
              >
                All
              </p>
            </div>
            {categories?.map((item) => (
              <div
                className={category === item?._id ? Style.active : ""}
                onClick={() => setCategory(item?._id)}
              >
                {/* <p>{item.title}</p> */}
                <p
                  style={{
                    color: 'white',
                    border: '0.3px solid #35ABAF',
                    padding: '10px 10px',
                    borderRadius: '20px',
                    transition: 'background-color 0.3s',
                    textAlign: 'center',
                    height: '44px',
                    margin: '0',

                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#35ABAF')} // Change background to skyblue on hover
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')} // Reset background on hover leave
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className={Style.blogs}>

            {
              result?.length ? (
                result?.map((item) => (
                  <div className={Style.left}>
                    <div className={Style.mainImg}>
                      <img src={BlogsImg} alt="" />
                    </div>
                    <h1>{item.title}</h1>
                    <p className={Style.description}>{item.description}</p>
                  </div>
                ))) : (<div style={{ height: '55vh', width: '100%' }}></div>)
            }

          </div>
        </div>
      </div>
    </Box>
  );
};
export default Blog;
