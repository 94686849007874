import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Avatar,
  Card,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHotSellingClbsAPI } from "../../redux/clb/getHotSellingClbs";
import Style from "../../styles/clb.module.scss";
import DividerUI from "../../ui/DividerUI";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import Snack from "../../ui/Snack";
import ReactSlider from "../../common/ReactSlider";
import LoaderUI from "../../ui/LoaderUI";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { Empty } from "../../helper/component";
import { useLocation } from "../../LocationContext";
const HotCellCLBs = () => {
  const getHotSellingClbsSelector = useSelector(
    (state) => state?.getHotSellingClbs
  );
  const { result, loading } = getHotSellingClbsSelector;
  console.log("🚀 ~ HotCellCLBs ~ result:==>1", result);

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult, error: userError } = getCurrentUserSelector;

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;
   const { convertToCurrency } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("Last Month");

  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const currency = localStorage.getItem("currency");
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleMenuFilter = (event) => {
    const { val } = event.currentTarget.dataset;
    // dispatch(getHotSellingClbsAPI({ ownerId: userResult?._id, filter: val }));
    dispatch(getHotSellingClbsAPI({ filter: val }));
    setFilter(val);
    setOpenMenu(false);
  };

  const handleFavirote = async (fav, clbId) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    await dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
    // await dispatch(getHotSellingClbsAPI({ ownerId: userResult?._id, filter }));
    await dispatch(getHotSellingClbsAPI({ filter }));
  };

  const handleOnCloes = () => setOpenMenu(false);

  useEffect(() => {
    // dispatch(getHotSellingClbsAPI({ ownerId: userResult?._id, filter }));
    dispatch(getHotSellingClbsAPI({ filter }));
  }, [dispatch]);

  return (
    <>
      <Box sx={{ mt: 10 }}>
        <Typography align="center" variant="h4">
          Hot Selling CLBs
        </Typography>
        <DividerUI />

        <Container>
          {loading ? (
            <LoaderUI />
          ) : (
            <ReactSlider autoplay={false}>
              {result && result.length ? (
                result?.map((item, idx) => {
                  return (
                    <>
                      <Paper className="SingelCLBBox" sx={{ m: 1 }}>
                        {/* <Stack
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                      > */}
                        {/* <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ px: 2, zIndex: 100 }}
                        > */}
                        {/* <Typography>Ending at :</Typography> */}
                        {/* <Typography color="primary">
                            {moment(item?.endDate).format(
                              "DD-MMM-YYYY HH:MM:a"
                            )}
                          </Typography> */}
                        {/* </Stack> */}
                        {/* {item?.favorites || item?.favorites != undefined ? (
                          <IconButton
                            onClick={() => handleFavirote(false, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleFavirote(true, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteBorderIcon
                              sx={{ color: "primary.main" }}
                            />
                          </IconButton>
                        )} */}
                        {/* </Stack> */}
                        <Box className="CLBBox">
                          {item?.type == "2d" && (
                            <img src={item?.files?.[0]} width={"100%"} />
                          )}
                          {item?.type == "3d" && (
                            <model-viewer
                              style={{ width: "100%", height: 250 }}
                              src={item?.files?.[0]}
                              ar-modes="webxr scene-viewer quick-look"
                              camera-controls
                              poster="poster.webp"
                              shadow-intensity="1"
                              disable
                            ></model-viewer>
                          )}
                        </Box>
                        <Stack sx={{ p: 2 }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mt: 2 }}
                            alignItems="center"
                          >
                            <Typography variant="p">{item?.title}</Typography>
                            <Typography variant="h6" className={Style?.price}>
                              {/* ₹ {item?.cost?.inr} */}
                              {/* {currency}{" "}
                              {(currency === "₹"
                                ? item?.cost?.inr ?? 0
                                : item?.cost?.usd ?? 0
                              ).toFixed(2)} */}
                               {currency}{" "}
                               {convertToCurrency(item?.cost?.inr)}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            justifyContent={`${
                              item?.isBidAble ? "space-between" : "end"
                            }  `}
                          >
                            {item?.isBidAble && (
                              <Button className="gradBtn" variant="contained">
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "#999999" }}
                                  >
                                    Higest Bid
                                  </Typography>
                                  {/* <Typography variant="p">
                                    ₹ {item?.cost?.inr}
                                  </Typography> */}
                                </Box>
                              </Button>
                            )}

                            {item?.isBidAble ? (
                              <Button
                                className={`${Style?.startBidBtn} gradBtn`}
                                variant="contained"
                                onClick={() => navigate(`/explore/${item._id}`)}
                              >
                                Start Bid
                              </Button>
                            ) : (
                              <Button
                                className={`${Style?.startPurchaseBtn} gradBtn`}
                                variant="contained"
                                onClick={() => navigate(`/explore/${item._id}`)}
                              >
                                Purchase
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </Paper>
                    </>
                  );
                })
              ) : (
                <>
                  <Grid
                    container
                    sx={{ justifyContent: "center", marginTop: "40px" }}
                  >
                    <Empty />
                    <br />
                  </Grid>
                  <Typography spacing={1} sx={{ mt: 2, textAlign: "center" }}>
                    No Data
                  </Typography>
                </>
              )}
            </ReactSlider>
          )}
        </Container>
      </Box>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};
export default HotCellCLBs;

// return (
// <>
//   <Box sx={{ mt: 10 }} className={Style.main}>
//     <Stack
//       direction="row"
//       alignItems="baseline"
//       justifyContent="space-between"
//     >
//       <Stack
//         direction="row"
//         alignItems="baseline"
//         justifyContent="center"
//         spacing={1}
//         sx={{ flex: 1 }}
//       >
//         <Typography
//           variant="h4"
//           sx={{ color: "#fff", mt: 8 }}
//           align="center"
//         >
//           Hot Selling CLBs
//         </Typography>
//         <Button
//           sx={{ fontSize: 30, color: "primary.main" }}
//           onClick={handleMenu}
//         >
//           {filter}
//         </Button>
//         <ExpandMoreIcon
//           onClick={handleMenu}
//           className="text-white "
//         ></ExpandMoreIcon>
//       </Stack>
//       <div>
//         <Menu anchorEl={anchorEl} open={openMenu} onClose={handleOnCloes}>
//           <MenuItem onClick={handleMenuFilter} data-val={"Last Month"}>
//             Last Month
//           </MenuItem>
//           <MenuItem onClick={handleMenuFilter} data-val={"Week"}>
//             Week
//           </MenuItem>
//           <MenuItem onClick={handleMenuFilter} data-val={"Day"}>
//             Day
//           </MenuItem>
//         </Menu>
//         <FilterListIcon></FilterListIcon>
//       </div>
//       <Button
//         sx={{ color: "primary.main", mr: 3 }}
//         onClick={() => navigate("/explore")}
//       >
//         View all
//       </Button>
//     </Stack>
//     <DividerUI />

//     <Container>
//       {loading ? (
//         <LoaderUI />
//       ) : (
//         <ReactSlider autoplay={false}>
//           <Grid container spacing={2}>
//             {result && result.length ? (
//               result.map((item) => (
//                 <Grid
//                   key={item._id}
//                   item
//                   lg={3}
//                   md={3}
//                   sm={6}
//                   xs={12}
//                   onClick={() => navigate(`/collection/${item._id}`)}
//                 >
//                   <Card sx={{ bgcolor: "#050505", p: 0 }}>
//                     <Box sx={{ width: "100%", height: 100 }}>
//                       <img
//                         src={item.cover}
//                         width={"100%"}
//                         height={"100%"}
//                         alt="coverImage"
//                       />
//                     </Box>
//                     <Stack
//                       sx={{ color: "#fff", mt: -5, p: 2 }}
//                       alignItems="center"
//                       spacing={2}
//                     >
//                       <Avatar
//                         src={item.picture}
//                         alt="picture"
//                         sx={{ width: 70, height: 70 }}
//                       />
//                       <Typography variant="h6">{item?.name}</Typography>
//                     </Stack>
//                   </Card>
//                 </Grid>
//               ))
//             ) : (

//               <Grid container spacing={2} className={Style.dummyImage}>
//                 {[1, 2, 3, 4].map((item) => (
//                   <Grid
//                   item
//                   lg={3}
//                   md={3}
//                   sm={6}
//                   xs={12}
//                   onClick={() => navigate(`/collection/${item._id}`)}
//                 >
//                   <Card sx={{ bgcolor: "#050505", p: 0 }}>
//                     <Box sx={{ width: "100%", height: 100 }}>
//                       <img
//                         src="https://applore-dev-projects-1.s3.ap-south-1.amazonaws.com/eduthum-assets/9aeb4438-tasha-the-witch-queen.jpg"
//                         width={"100%"}
//                         height={"100%"}
//                         alt="coverImage"
//                       />
//                     </Box>
//                     <Stack
//                       sx={{ color: "#fff", mt: -5, p: 2 }}
//                       alignItems="center"
//                       spacing={2}
//                     >
//                       <Avatar
//                         src={item.picture}
//                         alt="picture"
//                         sx={{ width: 70, height: 70 }}
//                       />
//                       <Typography variant="h6">{item?.name}</Typography>
//                       {/* <Typography variant="p">Rs. {item.price ? item.price : 0}</Typography> */}
//                     </Stack>
//                   </Card>
//                 </Grid>
//                 ))}
//               </Grid>
//             )}
//           </Grid>
//         </ReactSlider>
//       )}
//     </Container>
//   </Box>
//   {userNotLoggedIn ? (
//     <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
//   ) : null}
// </>
// );
// };

// export default HotCellCLBs;
