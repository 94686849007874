import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
} from "@mui/material";
import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import LogoImg from "../../assets/png/logoImg1.png";
import SideImg from "../../assets/png/signInImg1.png";
import { forgetPasswordAPI } from "../../redux/auth/forgetPassword";
import Style from "../../styles/auth.module.scss";
import { OutlinedInputUI } from "../../theme/muiTheme";
import Snack from "../../ui/Snack";
import toast from "react-hot-toast";
import { Typography } from "@mui/material";


const schema = yup
  .object({
    email: yup.string().required("Email is required field"),
  })
  .required();

export default function ForgetPassword() {
  const forgetPasswordSelector = useSelector((state) => state?.forgetPassword);
  const { message, error } = forgetPasswordSelector;

  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function isEmail(email) {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  const handleForgetPassword = async (data) => {
    if (!isEmail(data.email))
      return toast.error("Proper email is required.", {
        duration: 5000,
        position: "bottom-left",
      });
    await dispatch(forgetPasswordAPI(data));
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={4}
          md={4}
          sm={5}
          xs={12}
          className={Style?.signUp}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            height: "90vh",
            width: "90vw",
          }}
        >
          <Stack sx={{ py: 4, width: "100%" }}>
           

            <Box sx={{ width: 150, my: 3 }}> {/* Maintain some margin for the logo */}
              <Link to="/">
                <img src={LogoImg} alt="Eduthum" width={"100%"} />
              </Link>
            </Box>
            <Typography variant="h4" sx={{ mt: 4, color: "#35abaf", textAlign: "left" }}>
              Forgot Password
            </Typography>

            <form onSubmit={handleSubmit(handleForgetPassword)}>
              <Stack spacing={2} sx={{ mt: 5 }}>
                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Email <sup>*</sup></FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your email"
                    inputProps={{
                      style: { color: "#000", borderRadius: "8px" },
                    }}
                    type="email"
                    {...register("email")}
                  />
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                </FormControl>
                <Button variant="contained" type="submit" size="large">
                  Confirm Mail
                </Button>
              </Stack>
            </form>
          </Stack>
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12} bgcolor={'black'} className={Style?.bgImage}>
          <Box className={Style?.sideImg}>
            <img src={SideImg} alt="background image" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
