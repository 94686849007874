import axios from "axios";
import { toast } from "react-hot-toast";

// Flag to prevent multiple redirects
let hasRedirected = false;

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

const shownMessages = new Set();

instance.interceptors.request.use(
  function (config) {
    // Prevent requests if redirect has happened
    if (hasRedirected) {
      return Promise.reject("Redirected, no further requests should be made.");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response?.config?.method !== "get" && response.status !== 204) {
      const message = response?.data?.message;
      if (message && !shownMessages.has(message)) {
        shownMessages.add(message);
        toast.success(message, {
          duration: 5000,
          position: "bottom-left",
        });
      }
      // if (message === "Multiple orders processed successfully.") {
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 200);
      // }
      // if (message === "You are not an authorized user!" && !hasRedirected) {
      //   hasRedirected = true;
      //   localStorage.removeItem("accessToken");

     
      //   if (!hasRedirected) {
      //     window.location.href = "/signIn"; // Redirect to sign-in page
      //   }
      //   return Promise.reject("Redirecting to sign-in");
      // }
    }
    return response;
  },
  function (error) {
  //  debugger 
    if (error.response) {
      const message = error?.response?.data?.message;
      if (message && !shownMessages.has(message)) {
        shownMessages.add(message);
        toast.error(message, {
          duration: 5000,
          position: "bottom-left",
        });
      }
      
      // if (error.response.status === 401 && !hasRedirected) {
      //   hasRedirected = true;
      //   localStorage.removeItem("accessToken");

     
      //   if (hasRedirected) {
      //     window.location.href = "/signIn"; 
      //     hasRedirected=false
      //   }
      //   return Promise.reject("Redirecting to sign-in");
      // }
    }
    return Promise.reject(error);
  }
);

export default instance;
