import httpService from "../config/authAxios";

export async function createCollection(payload) {
  const coll = await httpService.post("/collection/", payload);
  return coll;
}
export async function getUserCollection() {
  const userAll = await httpService.get("/collection/user");
  return userAll;
}
export async function getAllCollection(filter) {
  const All = await httpService.get(`/collection/?filter=${filter}`);
  return All;
}
export async function getSingleCollection(id) {
  const collect = await httpService.get(`/collection/${id}`);
  let filteredClb = [];

  collect?.data?.result[0].clbs.forEach((clb) => {
    if (clb.status == "Approved") filteredClb.push(clb);
    // alert("asdf");
  });
  collect.data.result[0].clbs = filteredClb;
  return collect;
}
