import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../components/header/Header";
import { createCLBAPI } from "../redux/clb/createCLB";
import { getSIngleCLBAPI } from "../redux/clb/getSIngleCLB";
import Style from "../styles/clb.module.scss";
import { sellPurchasedCLBAPI } from "../redux/clb/sellPurchasedCLB";
import Snack from "../ui/Snack";
import { Modal } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    height: 900,
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  };

const schema = yup
  .object({
    title: yup.string().required("required field"),
    link: yup.string(),
    description: yup.string().required("required field"),
    type: yup.string(),
  })
  .required();

const Resell = ({ open, setOpen, CLB }) => {
  const getSIngleCLBSelector = useSelector((state) => state?.getSIngleCLB);
  const { result: getSIngleCLBResult } = getSIngleCLBSelector;

  const sellPurchasedCLBSelector = useSelector(
    (state) => state?.sellPurchasedCLB
  );
  const { message, error } = getSIngleCLBSelector;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CLBId } = useParams();

  const [isBidAble, setIsBidAble] = useState("Bid");
  const [cost, setCost] = useState({ inr: null, usd: null });
  const [costComp, setCostComp] = useState({ inr: null, usd: null });
  const [royaltyFee, setRoyaltyFee] = useState({
    percentage: null,
    INR: null,
    USD: null,
  });

  const handleCreateCLB = async (data) => {
    if (!costComp?.inr) {
      return setError("cost.inr", {
        type: "custom",
        message: "Price (INR) can not be empty!",
      });
    }

    let isBidAbleType;
    if (isBidAble == "Bid") isBidAbleType = true;
    else isBidAbleType = false;

    const clb = await dispatch(
      sellPurchasedCLBAPI({
        isBidAble: isBidAbleType,
        cost: { inr: Number(costComp?.inr), usd: Number(costComp?.usd) },
        royaltyFee: {
          percentage: Number(getSIngleCLBResult?.CLBs?.royaltyFee?.percentage),
          INR: Number(royaltyFee?.INR),
          USD: Number(royaltyFee?.USD),
        },
        CLBId,
      })
    );

    navigate(`/explore/${CLBId}`);
  };

  const handlePriceCom = async () => {
    let res = await axios.get(
      `https://openexchangerates.org/api/latest.json?app_id=f7e886874a7d4ab885688511c4357ac9&base=USD`
    );
    const priceCompData = res?.data;
    // const USD = priceCompData?.rates?.USD
    const INR = priceCompData?.rates?.INR;
    setCostComp({ inr: cost?.inr, usd: cost?.inr / INR });
    setRoyaltyFee({
      INR:
        (costComp?.inr * getSIngleCLBResult?.CLBs?.royaltyFee?.percentage) /
        100,
      USD:
        (costComp?.usd * getSIngleCLBResult?.CLBs?.royaltyFee?.percentage) /
        100,
    });
  };

  useEffect(() => {
    handlePriceCom();
  }, [cost]);
  useEffect(() => {
    dispatch(getSIngleCLBAPI(CLBId));
  }, [CLBId]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      title: getSIngleCLBResult?.CLBs?.title,
      description: getSIngleCLBResult?.CLBs?.description,
      link: getSIngleCLBResult?.CLBs?.linke,
      type: getSIngleCLBResult?.CLBs?.type,
    };

    reset({ ...defaultValues });
  }, [getSIngleCLBResult]);

  useEffect(() => {
    setRoyaltyFee({
      percentage: getSIngleCLBResult?.CLBs?.royaltyFee?.percentage,
      INR: getSIngleCLBResult?.CLBs?.royaltyFee?.INR,
      USD: getSIngleCLBResult?.CLBs?.royaltyFee?.USD,
    });
  }, [getSIngleCLBResult]);

  return (
    <Modal open={open} onClose={handleClose} className="modal">
   
    
        <Box className={Style.container} sx={style}  >
          <Grid container justifyContent='center' sx={{ p: 2,width: '100%' }}>
            <Grid lg={5} md={7} sm={9} xs={12} >
              <Card>
                <form onSubmit={handleSubmit(handleCreateCLB)}>
                  <Stack spacing={4}>
                    <Stack alignItems='center' spacing={1}>
                      <Typography color='primary' variant="h5">Sell CLB</Typography>
                      <Typography sx={{ color: '#fff' }} variant="p">Upload files</Typography>
                      <Typography sx={{ color: '#fff' }} variant="p">Drag or choose the file you want to upload</Typography>
                    </Stack>

                    <Card >
                      <Stack alignItems='center' justifyContent='center' sx={{ height: 300 }}>
                        {getSIngleCLBResult?.CLBs?.type == "2d" && <img src={getSIngleCLBResult?.CLBs?.files?.[0]} height={'100%'} />}
                        {getSIngleCLBResult?.CLBs?.type == "3d"
                          && <model-viewer
                            style={{ width: '100%', height: 250 }}
                            src={getSIngleCLBResult?.CLBs?.files?.[0]}
                            ar-modes="webxr scene-viewer quick-look"
                            camera-controls
                            poster="poster.webp"
                            shadow-intensity="1"
                            disable
                          ></model-viewer>
                        }
                      </Stack>
                    </Card>

                    <TextField label="Title"  {...register('title')} disabled />

                    <FormControl>
                      <TextField type="number" label="Price (in INR)" value={cost?.inr} onChange={({ target }) => setCost({ ...cost, inr: target?.value })} />
                      <FormHelperText error>{errors?.cost?.inr?.message}</FormHelperText>
                    </FormControl>

                    <TextField type="number" label="Price (in USD)" value={cost?.usd || costComp?.usd} disabled />
                    <TextField type='number' label="Royalty Percentage" value={royaltyFee?.percentage} disabled />
                    <TextField type='number' label="Royalty Fee(INR)" value={royaltyFee?.INR} disabled />
                    <TextField type='number' label="Royalty Fee(USD)" value={royaltyFee?.USD} disabled />

                    <FormControl>
                      <RadioGroup defaultValue={isBidAble} onChange={({ target }) => setIsBidAble(target.value)}>
                        <Stack direction='row' alignItems='center'>
                          <FormControlLabel sx={{ color: '#fff' }} value="Bid" control={<Radio />} label="Bid" />
                          <FormControlLabel sx={{ color: '#fff' }} value="Purchase" control={<Radio />} label="Purchase" />
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <Typography variant="p" sx={{ color: '#fff' }}>Properties</Typography>z
                      {getSIngleCLBResult?.CLBs?.property.map((field, idx) => {
                        return (
                          <>
                            <Stack direction='row' spacing={1} key={idx} alignItems='center'>
                              <Chip variant='outlined' color='primary' label={field?.name} />
                              <Chip variant='outlined' color='primary' label={field?.type} />
                            </Stack>
                          </>
                        )
                      })}
                    </Stack>

                    <Button type="submit" variant="contained">Sell</Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
     
    </Modal>
  );
};

export default Resell;
