import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { createCLBAPI } from "../../redux/clb/createCLB";
import { getAllCollectionAPI } from "../../redux/collection/getAllCollection";
import { getConfigMetaAPI } from "../../redux/config/getConfigMeta";
import { uploadFiles } from "../../service/upload";
import Style from "../../styles/clb.module.scss";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import BuyCLBConfimation from "../../dialog/BuyCLBConfimation";
import PayGasFees from "../../dialog/payGasFees";
import { getEstimatedGasFeesAPI } from "../../redux/clb/estimateGasPrice";
import toast from "react-hot-toast";
import HttpService from "../../config/authAxios";
import getUsdInrCurrentRates from "../../helper/usdInrPriceRates";

const schema = yup
  .object({
    title: yup.string().required("Title is required."),
    link: yup.string().required("External link is required."),
    description: yup.string().required("Description is required."),
    collection: yup.string().required("Collection is required."),
    clbType: yup.string().required("Type is required."),
    cost: yup.object({
      inr: yup
        .number()
        .typeError("Price (INR) must be a number.")
        .required("Price (INR) is required.")
        .positive("Price (INR) must be greater than 0."),
    }),
    royaltyFee: yup.object({
      percentage: yup
        .number()
        .typeError("Royalty percentage is required.")
        .required("Royalty percentage is required."),
      // .max(99.9, "Royalty percentage cannot exceed 99.9%."),
    }),
    clbAsset: yup
      .array()
      .min(1, "Please Upload CLB Asset.")
      .required("CLB asset is required."),
  })
  .required();

let currencyRates;
const getCurrencyRates = async () => {
  currencyRates = await getUsdInrCurrentRates();
  return currencyRates;
};

getCurrencyRates();

const CreateCLB = () => {
  const getAllCategorySelector = useSelector((state) => state?.getAllCategory);
  const { result: categoryData } = getAllCategorySelector;

  console.log("categoryData", categoryData)

  const getAllCollectionSelector = useSelector(
    (state) => state?.getAllCollection
  );
  const { result: collection } = getAllCollectionSelector;

  const getEstimatedGasFeesSelector = useSelector(
    (state) => state?.getEstimatedGasFees
  );
  const { result: gasFees } = getEstimatedGasFeesSelector;

  const calculatedGasFees = (+gasFees?.UsdPrice * 3).toFixed(2);
  const formMethods = useForm(); // Assuming you're using React Hook Form

  const {
    control,
    register,
    watch,
    trigger,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "property",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getFromLocalStorage = (key) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const [imageLoading, setImageLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [clbAsset, setCLBAsset] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.clbAsset || [];
  });
  const [property, setProperty] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.property || { name: "", type: "" }
    );
  });
  const [expDate, setExpDate] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.expDate || null;
  });
  const [isBidAble, setIsBidAble] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.isBidAble || "Bid";
  });
  const [collect, setCollect] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.collect || null;
  });
  const [category, setCategory] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.category || null;
  });
  const [imageIpfs, setImageIpfs] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.imageIpfs || "";
  });
  const [cost, setCost] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.cost || { inr: null, usd: null }
    );
  });
  const [costComp, setCostComp] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.costComp || {
        inr: null,
        usd: null,
      }
    );
  });
  const [royaltyFee, setRoyaltyFee] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.royaltyFee || {
        percentage: null,
        INR: null,
        USD: null,
      }
    );
  });

  const isApiCalled = useRef(false);
  const [type, setType] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.type || null;
  });
  const [isGasFeesPaid, setIsGasFeesPaid] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.isGasFeesPaid || false;
  });
  const [gasPayModalOpen, setGasPayModalOpen] = useState(false);
  const [gasFeeError, setGasFeeError] = useState("");

  useEffect(() => {
    const savedFormData = getFromLocalStorage("createCLBFormData");

    if (savedFormData) {
      for (const [key, value] of Object.entries(savedFormData)) {
        setValue(key, value);
      }
    }
  }, []);

  useEffect(() => {
    saveToLocalStorage("createCLBFormData", watch());
    saveToLocalStorage("createCLBStates", {
      clbAsset,
      property,
      expDate,
      isBidAble,
      collect,
      category,
      imageIpfs,
      cost,
      costComp,
      royaltyFee,
      type,
      isGasFeesPaid,
    });
  }, [
    watch(),
    clbAsset,
    property,
    expDate,
    isBidAble,
    collect,
    category,
    imageIpfs,
    cost,
    costComp,
    royaltyFee,
    type,
    isGasFeesPaid,
  ]);

  const [toastShown, setToastShown] = useState({
    success: false,
    error: false,
  });
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [userData, setUserData] = useState();
  const [userId, setUserId] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [cryptoFields, setCryptoFields] = useState({
    walletAddress: "",
    paymentInfo: "",
  });
  const [inrFields, setInrFields] = useState({
    accountNumber: "",
    ifscCode: "",
    bankName: "",
  });
  //  const [errors, setErrors] = useState({});
  //  const [selectedCLB, setSelectedCLB] = useState(null);
  console.log("selected", category)


  const isToastShown = useRef(false);

  useEffect(() => {
    // Set userId from localStorage once it's available
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);
  useEffect(() => {
    // Fetch user details only if userId is available
    const getPaymentInfo = async () => {
      if (!userId) return; // Ensure userId is defined

      try {
        const response = await HttpService.post(`/users/getUserById`, {
          userId: userId,
        });
        console.log("User details fetched:", response.data.result.bankDetail);
        setUserData(response?.data?.result);
      } catch (error) {
        console.log("Error fetching user details:", error);
      }
    };

    getPaymentInfo();
  }, [userId]);

  // const getPaymentInfo = async () => {
  //   try {
  //     const response = await HttpService.post(`/users/getUserById`, {
  //       userId: userId,
  //     });
  //     console.log("resssssssssssssssss", response.data.result.bankDetail);
  //     setUserData(response?.data?.result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getPaymentInfo();
  // }, [userId]);
  const coingateOrderActionHandler = async () => {
    try {
      const orderId = localStorage.getItem("Coingate-gas-priceId");
      if (!orderId) return;

      const response = await HttpService.get(
        `/payments/coingate-success/${orderId}`
      );

      if (response?.data?.status === "paid" && !isToastShown.current) {
        // toast.success("Payment successful!", {
        //   duration: 5000,
        //   position: "bottom-left",
        // });
        // isToastShown.current = true; // Mark toast as shown
        setIsGasFeesPaid(true);
      } else if (
        (response.data.status === "canceled" ||
          response.data.status === "expired" ||
          response.data.status === "invalid") &&
        !isToastShown.current
      ) {
        toast.error("Payment failed or expired.", {
          duration: 5000,
          position: "bottom-left",
        });
        isToastShown.current = true; // Mark toast as shown
        localStorage.removeItem("Coingate-gas-priceId");
      }
    } catch (error) {
      console.error("Error checking payment status:", error.message);
      localStorage.removeItem("Coingate-gas-priceId");
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem("Coingate-gas-priceId")) {
  //     coingateOrderActionHandler();
  //   }
  // }, []);

  useEffect(() => {
    console.log("useEffect triggered");
    if (!isApiCalled.current && localStorage.getItem("Coingate-gas-priceId")) {
      coingateOrderActionHandler();
      isApiCalled.current = true; // Mark API as called
    }
  }, []);

  const autosetFileType = async (type) => {
    if (type.startsWith("image")) {
      setType("2d");
      return;
    }
    setType("3d");
  };
  const checkImageContent = async (imageData) => {
    const response = await axios.post(
      `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      {
        requests: [
          {
            image: { content: imageData },
            features: [{ type: "SAFE_SEARCH_DETECTION" }],
          },
        ],
      }
    );

    const safeSearchResult = response.data.responses[0].safeSearchAnnotation;
    return (
      safeSearchResult.adult === "VERY_LIKELY" ||
      safeSearchResult.violence === "VERY_LIKELY"
    );
  };

  // google api only takes files in base64 format
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Return base64 without the data prefix
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const THREE_D_FILE_EXTENSIONS = [
    ".glb",
    ".gltf",
    ".obj",
    ".fbx",
    ".dae",
    ".3ds",
    ".stl",
    ".svg",
  ];

  const getFileExtension = (filename) => {
    return filename
      .slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
  };

  const handleUploadCLB = async (event) => {
    setImageLoading(true);
    setCLBAsset([]);

    const { target } = event;

    if (!target || !target.files || target.files.length === 0) {
      toast.error("No file selected. Please choose an image to upload.");
      setImageLoading(false);
      return;
    }

    const file = target.files[0];

    if (!file) {
      toast.error("No file selected. Please choose an image to upload.");
      setImageLoading(false);
      return;
    }

    autosetFileType(file.type);
    const fileExtension = `.${getFileExtension(file.name)}`;
    const isThreeDFile = THREE_D_FILE_EXTENSIONS.includes(fileExtension);

    try {
      if (!isThreeDFile) {
        const base64Image = await fileToBase64(file);
        const isSensitive = await checkImageContent(base64Image);

        if (isSensitive) {
          toast.error(
            "This image contains sensitive content and cannot be uploaded."
          );
          setImageLoading(false);
          return;
        }
      } else {
        console.log("3D file detected. Skipping explicit content check.");
      }

      const ipfs = true;
      const data = await uploadFiles(file, ipfs);
      setImageIpfs(data?.ipfsFile);
      setCLBAsset([data?.fileUrl]);

      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during the upload process.");
    } finally {
      setImageLoading(false);
    }
  };

  // const handleCreateCLB = async (data) => {
  //   // Trigger validation for all fields
  //   console.log("data", data);
  //   const isValid = await trigger();
  //   if (!isValid) {
  //     return;
  //   }
  //   debugger;

  //   if (!isGasFeesPaid) {
  //     setGasFeeError("Gas fees must be paid before creating CLB.");
  //     return;
  //   }

  //   setTempLoading(true);
  //   let isBidAbleType;
  //   if (isBidAble == "Bid") {
  //     if (!expDate) {
  //       return setError("expDateError", {
  //         type: "custom",
  //         message: "Expiry Date can not be empty!",
  //       });
  //     }
  //     isBidAbleType = true;
  //   } else {
  //     isBidAbleType = false;
  //     setExpDate(null);
  //   }

  //   const gasPrice = {
  //     inr: (
  //       Number(localStorage.getItem("Coingate-gas-price")) * currencyRates?.INR
  //     ).toFixed(2),
  //     usd: Number(localStorage.getItem("Coingate-gas-price")),
  //   };

  //   const clb = dispatch(
  //     createCLBAPI({
  //       isBidAble: isBidAbleType,
  //       collect: collect,
  //       property: fields,
  //       endDate: expDate,
  //       files: clbAsset,
  //       orderId: JSON.parse(localStorage.getItem("Coingate-gas-priceId")),
  //       category,
  //       gasPrice: gasPrice,
  //       cost: { inr: Number(costComp?.inr), usd: Number(costComp?.usd) },
  //       royaltyFee: {
  //         percentage: Number(royaltyFee?.percentage),
  //         INR: Number(royaltyFee?.INR),
  //         USD: Number(royaltyFee?.USD),
  //       },
  //       ipfsFile: imageIpfs,
  //       type,
  //       ...data,
  //     })
  //   );

  //   setTempLoading(false);
  //   localStorage.removeItem("Coingate-gas-priceId");
  //   localStorage.removeItem("createCLBFormData");
  //   localStorage.removeItem("createCLBStates");
  //   localStorage.removeItem("Coingate-gas-price");

  //   if (!clb?.payload?.error) {
  //     navigate("/explore");
  //   }
  // };
  const handleCreateCLB = async (data) => {
    console.log("udddddddddddd", userData);
    // Validate all required fields first
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    // Check if bank details or crypto details are missing
    // if (
    //   userData?.payout === "inr" &&
    //   userData?.bankDetail &&
    //   userData?.bankDetail.accountNumber
    // ) {
    //   console.log("Bank details already set. Skipping payment popup.");
    // } else if (!selectedPaymentType) {
    //   setOpenPaymentPopup(true); // Open popup if no bank/crypto details exist
    //   return;
    // }

    if (
      userData?.bankDetail?.accountNumber // Check if bank details exist
    ) {
      console.log("Bank details already set. Skipping payment popup.");
    } else if (!selectedPaymentType) {
      setOpenPaymentPopup(true); // Open the popup if no bank/crypto details exist
      return;
    }

    // Ensure gas fees are paid
    if (!isGasFeesPaid) {
      setGasFeeError("Gas fees must be paid before creating CLB.");
      return;
    }

    setTempLoading(true);

    let isBidAbleType;
    if (isBidAble === "Bid") {
      if (!expDate) {
        setError("expDateError", {
          type: "custom",
          message: "Expiry Date cannot be empty!",
        });
        setTempLoading(false);
        return;
      }
      isBidAbleType = true;
    } else {
      isBidAbleType = false;
      setExpDate(null);
    }

    const gasPrice = {
      inr: (
        Number(localStorage.getItem("Coingate-gas-price")) * currencyRates?.INR
      ).toFixed(2),
      usd: Number(localStorage.getItem("Coingate-gas-price")),
    };

    const clb = dispatch(
      createCLBAPI({
        isBidAble: isBidAbleType,
        collect,
        property: fields,
        endDate: expDate,
        files: clbAsset,
        orderId: JSON.parse(localStorage.getItem("Coingate-gas-priceId")),
        category,
        gasPrice: gasPrice,
        cost: { inr: Number(costComp?.inr), usd: Number(costComp?.usd) },
        royaltyFee: {
          percentage: Number(royaltyFee?.percentage),
          INR: Number(royaltyFee?.INR),
          USD: Number(royaltyFee?.USD),
        },
        ipfsFile: imageIpfs,
        type,
        ...data,
      })
    );

    setTempLoading(false);
    localStorage.removeItem("Coingate-gas-priceId");
    localStorage.removeItem("createCLBFormData");
    localStorage.removeItem("createCLBStates");
    localStorage.removeItem("Coingate-gas-price");

    if (!clb?.payload?.error) {
      navigate("/explore");
    }
  };

  const handleAddProperty = () => {
    append({ name: property?.name, type: property?.type });
    setProperty({ name: "", type: "" });
  };
  const handleDeleteProperty = (idx) => remove(idx);

  const handlePriceCom = async () => {
    const { USD, INR } = await getUsdInrCurrentRates();
    setCostComp({ inr: cost?.inr, usd: (cost?.inr / INR).toFixed(2) });
  };

  const handleChangeRoyaltyFee = ({ target }) => {
    let percentageVal = target?.value;
    if (percentageVal < 0) {
      return;
    }
    const royaltyINR = ((costComp?.inr || 0) * percentageVal) / 100;
    const royaltyUSD = ((costComp?.usd || 0) * percentageVal) / 100;

    // setRoyaltyFee({
    //   percentage: percentageVal,
    //   INR: (costComp?.inr * percentageVal) / 100,
    //   USD: ((costComp?.usd * percentageVal) / 100).toFixed(2),
    // });
    setRoyaltyFee({
      percentage: percentageVal,
      INR: royaltyINR,
      USD: royaltyUSD.toFixed(2),
    });
    setValue("royaltyFee.INR", royaltyINR);
    setValue("royaltyFee.USD", royaltyUSD);
  };

  useEffect(() => {
    dispatch(getAllCategoryAPI());
    dispatch(getAllCollectionAPI());
    dispatch(getConfigMetaAPI());
    dispatch(getEstimatedGasFeesAPI());
  }, []);

  useEffect(() => {
    handlePriceCom();
  }, [cost]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleGasFeeClick = async () => {
    // Clear any existing gas fee error
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    setGasFeeError("");

    // If all validations pass, open the modal
    setGasPayModalOpen(true);
  };

  const constructPayload = () => {
    const userId = localStorage.getItem("userId"); // Replace with actual user ID

    if (selectedPaymentType === "crypto") {
      return {
        id: userId,
        payout: "crypto",
        cryptoWallet: {
          walletAddress: cryptoFields.walletAddress,
        },
      };
    } else if (selectedPaymentType === "inr") {
      return {
        id: userId,
        payout: "inr",
        bankDetail: {
          name: inrFields.name,
          accountNumber: inrFields.accountNumber,
          ifscCode: inrFields.ifscCode,
          bankName: inrFields.bankName,
          branchName: inrFields.branchName,
          accountType: inrFields.accountType,
        },
      };
    }
    return null; // Return null if no payment type is selected
  };
  const submitPaymentDetails = async () => {
    const payload = constructPayload();

    if (!payload) {
      toast.error("Please fill in all required details.");
      return;
    }

    try {
      const response = await HttpService.post("/users/bankDetails", payload);
      if (response.status === 200) {
        toast.success("Payment details submitted successfully!");
        setOpenPaymentPopup(false); // Close modal
      } else {
        toast.error("Failed to submit payment details. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting payment details:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  const isFormValid = () => {
    if (selectedPaymentType === "crypto") {
      return cryptoFields.walletAddress && cryptoFields.paymentInfo;
    } else if (selectedPaymentType === "inr") {
      return (
        inrFields.accountNumber &&
        inrFields.ifscCode &&
        inrFields.bankName &&
        inrFields.branchName &&
        inrFields.accountType
      );
    }
    return false;
  };
  useEffect(() => {
    setDisableSubmit(!isFormValid());
  }, [cryptoFields, inrFields, selectedPaymentType]);

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Box className={Style.container}>
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleSubmit(handleCreateCLB)}>
                  <Stack spacing={4}>
                    <Typography color="primary" variant="h5" align="center">
                      Create CLB
                    </Typography>
                    <FormControl>
                      <Box className="dropCard">
                        <input
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          accept="image/*,.glb,.gltf,.svg"
                          // onChange={(event) => {
                          //   handleUploadCLB(event);
                          //   trigger("clbAsset"); // Trigger validation for clbAsset
                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            handleUploadCLB(event);
                            if (file) {
                              const newClbAsset = [...clbAsset, file.name]; // Add file name or other identifier
                              const fileType =
                                file.type.startsWith("image") ||
                                  file.type === "image/svg+xml"
                                  ? "2d"
                                  : "3d";
                              setValue("clbAsset", newClbAsset); // Update react-hook-form state
                              trigger("clbAsset"); // Trigger validation for clbAsset
                              setValue("clbType", fileType); // Update react-hook-form state
                              trigger("clbType");
                            }
                          }}
                        />
                        <label htmlFor="raised-button-file">
                          <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                            <Typography variant="h6">
                              Upload CLB Asset!<sup>*</sup>
                            </Typography>
                            <ArrowCircleUpIcon
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "#999999",
                                borderRadius: 10,
                                p: 2,
                              }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="subtitle2">
                                JPG, PNG, GIF, SVG
                              </Typography>
                              <Typography variant="subtitle2">
                                GLB, GLTF.
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">
                              Max size: 100 MB
                            </Typography>
                          </Stack>
                        </label>
                        <Grid container>
                          {imageLoading ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            clbAsset?.map((ele, index) => (
                              <Box
                                key={index}
                                height={"50%"}
                                className="CLBBox"
                              >
                                {["jpg", "png", "svg", "gif"].includes(
                                  ele?.split(".")?.pop()?.toLowerCase()
                                ) && (
                                    <img
                                      src={ele}
                                      width={"100%"}
                                      style={{ margin: "5px" }}
                                      alt="uploaded_img"
                                    />
                                  )}
                                {ele?.split(".")?.pop()?.toLowerCase() ===
                                  "glb" && (
                                    <model-viewer
                                      style={{
                                        width: "100%",
                                        height: 250,
                                        margin: "5px",
                                      }}
                                      src={ele}
                                      ar-modes="webxr scene-viewer quick-look"
                                      camera-controls
                                      poster="poster.webp"
                                      shadow-intensity="1"
                                    ></model-viewer>
                                  )}
                              </Box>
                            ))
                          )}
                        </Grid>
                      </Box>
                      <FormHelperText error>
                        {errors?.clbAsset?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        label="Title *"
                        {...register("title")}
                        onChange={(event) => {
                          setValue("title", event.target.value); // Update value
                          trigger("title"); // Trigger validation for title
                        }}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        label="External Link *"
                        {...register("link")}
                        onChange={(event) => {
                          setValue("link", event.target.value);
                          trigger("link");
                        }}
                      />
                      <FormHelperText error>
                        {errors?.link?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        multiline
                        rows={3}
                        label="Description *"
                        {...register("description")}
                        onChange={(event) => {
                          setValue("description", event.target.value);
                          trigger("description");
                        }}
                      />
                      <FormHelperText error>
                        {errors?.description?.message}
                      </FormHelperText>
                    </FormControl>
                    {/* <FormControl>
                      <TextField
                        type="number"
                        label="Price (in INR) *"
                        value={cost?.inr}
                        onChange={(event) => {
                          const value = event.target.value;
                          setCost({ ...cost, inr: value });
                          setValue("cost.inr", value);
                          trigger("cost.inr");
                        }}
                      />
                      <FormHelperText error>
                        {errors?.cost?.inr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in USD)"
                        className="text-white-imp"
                        value={cost?.usd || costComp?.usd}
                        onChange={({ target }) => {
                          const value = target.value;
                          console.log("value",value)
                          setCost({ ...cost, usd: value });
                          setValue("cost.usd", value);
                          trigger("cost.usd");
                        }}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.cost?.usd?.message}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in INR) *"
                        value={cost?.inr}
                        onChange={async (event) => {
                          const value = event.target.value;
                          setCost({ ...cost, inr: value }); // Update INR immediately in state
                          setValue("cost.inr", value);
                          trigger("cost.inr");

                          if (value) {
                            const { INR } = await getUsdInrCurrentRates(); // Fetch the latest conversion rate
                            const usdValue = (value / INR).toFixed(2);
                            setCost((prevCost) => ({
                              ...prevCost,
                              usd: usdValue,
                            })); // Update USD based on latest rate
                            setValue("cost.usd", usdValue); // Update form state for USD
                            trigger("cost.usd");
                          }
                        }}
                      />
                      <FormHelperText error>
                        {errors?.cost?.inr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in USD)"
                        className="text-white-imp"
                        value={Number(cost?.usd || costComp?.usd).toFixed(2)}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.cost?.usd?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        label="Royalty Percentage *"
                        {...register("royaltyFee.percentage")}
                        value={royaltyFee?.percentage || ""}
                        onChange={(event) => {
                          handleChangeRoyaltyFee(event);
                          const percentage = event.target.value;
                          setValue("royaltyFee.percentage", percentage); // Update react-hook-form state
                          trigger("royaltyFee.percentage");
                        }}
                      />
                      <FormHelperText error>
                        {errors?.royaltyFee?.percentage?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        className="text-white-imp"
                        label="Royalty Fee (INR)"
                        value={royaltyFee?.INR}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.royaltyFeeINRErr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        className="text-white-imp"
                        label="Royalty Fee (USD)"
                        value={Number(royaltyFee?.USD).toFixed(2)}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.royaltyFeeUSDErr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        disablePortal
                        options={categoryData}
                        value={categoryData?.find((item) => item._id === category) || null}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, val) => {
                          setCategory(val?._id);
                          // setCategory(val?.name);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Category *" />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.categoryError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        disablePortal
                        options={collection || []}
                        value={collection?.find((item) => item._id === collect) || null}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, val) => {
                          // setCollect(val?.name);
                          setCollect(val?._id);
                          clearErrors("collection");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...register("collection")}
                            {...params}
                            label="Collection *"
                          />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.collection?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        className={Style.typeText}
                        disablePortal
                        options={["2d", "3d"]}
                        onChange={(event, val) => {
                          setType(val);
                          setValue("clbType", val); // Update react-hook-form state
                          trigger("clbType"); // Trigger validation for clbType
                        }}
                        value={type}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            {...register("clbType")}
                          />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.clbType?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <RadioGroup
                        defaultValue={isBidAble}
                        onChange={({ target }) => setIsBidAble(target.value)}
                      >
                        <Stack direction="row" alignItems="center">
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Bid"
                            control={<Radio />}
                            label="Bid"
                          />
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Purchase"
                            control={<Radio />}
                            label="Purchase"
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <Box sx={{ my: 2 }}>
                      <Typography variant="p" sx={{ color: "#fff" }}>
                        Properties
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ mt: 2 }}
                        alignItems="center"
                      >
                        <TextField
                          label="Name"
                          value={property?.name}
                          onChange={({ target }) =>
                            setProperty({ ...property, name: target?.value })
                          }
                        />
                        <TextField
                          label="Type"
                          value={property?.type}
                          onChange={({ target }) =>
                            setProperty({ ...property, type: target?.value })
                          }
                        />
                        <IconButton
                          disabled={!property?.name || !property?.type}
                          onClick={handleAddProperty}
                        >
                          <AddCircleIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Stack>

                      <Stack spacing={2} sx={{ mt: 2 }}>
                        {fields.map((field, idx) => {
                          return (
                            <>
                              <Stack
                                direction="row"
                                spacing={1}
                                key={idx}
                                alignItems="center"
                              >
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={field?.name}
                                  sx={{
                                    maxWidth: "200px", // Set max width
                                    overflow: "hidden", // Hide overflowed content
                                    textOverflow: "ellipsis", // Show ellipsis for overflowed text
                                    whiteSpace: "nowrap", // Prevent text wrapping
                                  }}
                                />
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={field?.type}
                                  sx={{
                                    maxWidth: "200px", // Set max width
                                    overflow: "hidden", // Hide overflowed content
                                    textOverflow: "ellipsis", // Show ellipsis for overflowed text
                                    whiteSpace: "nowrap", // Prevent text wrapping
                                  }}
                                />
                                <IconButton
                                  onClick={() => handleDeleteProperty(idx)}
                                >
                                  <DeleteIcon sx={{ color: "#fff" }} />
                                </IconButton>
                              </Stack>
                            </>
                          );
                        })}
                      </Stack>
                    </Box>

                    <FormControl>
                      {isBidAble == "Bid" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ bgcolor: "#484848", borderRadius: 2 }}
                            label="Expiry Date *"
                            slotProps={{
                              textField: { size: "small" },
                              rightArrowIcon: {
                                style: { color: "black" },
                              },
                              leftArrowIcon: {
                                style: { color: "black" },
                              },
                            }}
                            slots={{
                              leftArrowIcon: ArrowLeft,
                              rightArrowIcon: ArrowRight,
                            }}
                            minDate={new Date()}
                            onChange={(value) => {
                              setExpDate(moment(value).format("YYYY-MM-DD"));
                            }}
                          />
                        </LocalizationProvider>
                      ) : null}
                      <FormHelperText error>
                        {errors?.expDateError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      {!isGasFeesPaid ? (
                        <Button
                          variant="contained"
                          onClick={() => setGasPayModalOpen(true)}
                        >
                          Pay Gas Fee (${calculatedGasFees})
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{ background: "green", color: "#fff" }}
                        >
                          Paid Successfully
                        </Button>
                      )}
                    </FormControl>
                    {gasFeeError?.length !== 0 && (
                      <Typography color="error">{gasFeeError}</Typography>
                    )}
                    {tempLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        disabled={!isGasFeesPaid}
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          if (userData?.bankDetail?.accountNumber) {
                            console.log(
                              "Bank details already set. Skipping payment popup."
                            );
                            handleSubmit(handleCreateCLB)();
                          } else {
                            console.log(
                              "No bank details found. Opening payment popup."
                            );
                            setOpenPaymentPopup(true);
                          }
                        }}
                      >
                        Create
                      </Button>
                    )}
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openPaymentPopup}
          onClose={() => setOpenPaymentPopup(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiDialog-paper": {
              padding: 3,
              borderRadius: 2,
              backgroundColor: "#ffffff", // Ensure white background
              color: "#000000", // Black text for better contrast
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#333", // Darker text color for contrast
            }}
          >
            Select Payment Method
          </DialogTitle>
          <DialogContent>
            {!selectedPaymentType ? (
              <Stack
                direction="row"
                spacing={3}
                justifyContent="center"
                sx={{ marginTop: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log("Payment Type: Crypto");
                    setSelectedPaymentType("crypto");
                  }}
                  sx={{
                    padding: 2,
                    fontSize: "1rem",
                  }}
                >
                  Pay with Crypto
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log("Payment Type: INR");
                    setSelectedPaymentType("inr");
                  }}
                  sx={{
                    padding: 2,
                    fontSize: "1rem",
                  }}
                >
                  Pay with INR
                </Button>
              </Stack>
            ) : selectedPaymentType === "crypto" ? (
              <>
                <TextField
                  fullWidth
                  label="Wallet Address"
                  value={cryptoFields.walletAddress}
                  onChange={(e) =>
                    setCryptoFields({
                      ...cryptoFields,
                      walletAddress: e.target.value,
                    })
                  }
                  error={!!errors.walletAddress}
                  helperText={errors.walletAddress}
                  sx={{
                    mt: 3,
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f9f9f9", // Light field background
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focused effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Payment Info"
                  value={cryptoFields.paymentInfo}
                  onChange={(e) =>
                    setCryptoFields({
                      ...cryptoFields,
                      paymentInfo: e.target.value,
                    })
                  }
                  error={!!errors.paymentInfo}
                  helperText={errors.paymentInfo}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#555" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f9f9f9",
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Account Number"
                  value={inrFields.accountNumber}
                  onChange={(e) =>
                    setInrFields({
                      ...inrFields,
                      accountNumber: e.target.value,
                    })
                  }
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber}
                  sx={{
                    input: { color: "#000" },
                    mt: 3,
                    "& .MuiInputLabel-root": { color: "#555" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f9f9f9",
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="IFSC Code"
                  value={inrFields.ifscCode}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, ifscCode: e.target.value })
                  }
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#555" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f9f9f9",
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Bank Name"
                  value={inrFields.bankName}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, bankName: e.target.value })
                  }
                  error={!!errors.bankName}
                  helperText={errors.bankName}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#555" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f9f9f9",
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", mt: 3 }}>
            <Button
              variant="outlined"
              onClick={() => {
                console.log("Payment Cancelled");
                setOpenPaymentPopup(false);
                setSelectedPaymentType("");
                // setErrors({});
              }}
            >
              Cancel
            </Button>
            <Button
              // variant="contained"
              onClick={() => {
                console.log("Payment Submitted:", {
                  paymentType: selectedPaymentType,
                  data:
                    selectedPaymentType === "crypto" ? cryptoFields : inrFields,
                });
                handleSubmit();
              }}
              disabled={Object.keys(errors).length > 0}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPaymentPopup}
          onClose={() => setOpenPaymentPopup(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiDialog-paper": {
              padding: 3,
              borderRadius: 2,
              backgroundColor: "#ffffff", // Ensure white background
              color: "#000000", // Black text for better contrast
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#333", // Darker text color for contrast
            }}
          >
            Select Payment Method
          </DialogTitle>
          <DialogContent>
            {!selectedPaymentType ? (
              <Stack
                direction="row"
                spacing={3}
                justifyContent="center"
                sx={{ marginTop: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={() => setSelectedPaymentType("crypto")}
                  sx={{ padding: 2, fontSize: "1rem" }}
                >
                  Pay with Crypto
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setSelectedPaymentType("inr")}
                  sx={{ padding: 2, fontSize: "1rem" }}
                >
                  Pay with INR
                </Button>
              </Stack>
            ) : selectedPaymentType === "crypto" ? (
              <>
                <TextField
                  fullWidth
                  label="Wallet Address"
                  value={cryptoFields.walletAddress}
                  onChange={(e) =>
                    setCryptoFields({
                      ...cryptoFields,
                      walletAddress: e.target.value,
                    })
                  }
                  error={!!errors.walletAddress}
                  helperText={errors.walletAddress}
                  // sx={{ mt: 3 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Account Holder Name"
                  value={inrFields.name}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, name: e.target.value })
                  }
                  error={!!errors.name}
                  helperText={errors.name}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Account Number"
                  value={inrFields.accountNumber}
                  onChange={(e) =>
                    setInrFields({
                      ...inrFields,
                      accountNumber: e.target.value,
                    })
                  }
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="IFSC Code"
                  value={inrFields.ifscCode}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, ifscCode: e.target.value })
                  }
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Bank Name"
                  value={inrFields.bankName}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, bankName: e.target.value })
                  }
                  error={!!errors.bankName}
                  helperText={errors.bankName}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Branch Name"
                  value={inrFields.branchName}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, branchName: e.target.value })
                  }
                  error={!!errors.branchName}
                  helperText={errors.branchName}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Account Type"
                  value={inrFields.accountType}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, accountType: e.target.value })
                  }
                  error={!!errors.accountType}
                  helperText={errors.accountType}
                  // sx={{ mt: 2 }}
                  sx={{
                    input: { color: "#000" },
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      padding: "10px", // Add padding for better visibility
                    },
                    "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999", // Hover effect
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Focus effect
                      },
                    },
                  }}
                />
              </>
            )}
          </DialogContent>

          <DialogActions sx={{ justifyContent: "space-between", mt: 3 }}>
            <Button
              style={{ color: "Green" }}
              onClick={() => {
                console.log("Payment Cancelled");
                setOpenPaymentPopup(false);
                setSelectedPaymentType("");
                // setErrors({});
              }}
            >
              Cancel
            </Button>
            <Button
              // variant="contained"
              style={{ color: "Green" }}
              onClick={() => {
                console.log("Payment Submitted:", {
                  paymentType: selectedPaymentType,
                  data:
                    selectedPaymentType === "crypto" ? cryptoFields : inrFields,
                });
                submitPaymentDetails();
              }}
              disabled={disableSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {gasPayModalOpen && (
        <PayGasFees
          open={gasPayModalOpen}
          setOpen={setGasPayModalOpen}
          gasPrice={calculatedGasFees}
        />
      )}
    </>
  );
};
export default CreateCLB;
