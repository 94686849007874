import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./styles/global.scss";
import "./styles/button.scss";
import "./styles/box.scss";
import "./styles/card.scss";
import "./styles/mui.scss";
import { MetaMaskProvider } from "@metamask/sdk-react";
import { LocationProvider } from "./LocationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MetaMaskProvider
        debug={true}
        sdkOptions={{
          logging: {
            developerMode: true,
          },
          checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
          dappMetadata: {
            name: "Demo React App",
            url: window.location.host,
          },
        }}
      >
        <LocationProvider><App /></LocationProvider>
       
      </MetaMaskProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
