import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import modelPath from "../../assets/3d/model.glb";
import StartImg1 from "../../assets/png/startImg1.png";
import StartImg2 from "../../assets/png/startImg2.png";
import spinner from "../../assets/svg/spinner.svg";
import { getAllBanner } from "../../service/banner.service";
import Style from "../../styles/landing.module.scss";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";

const Landing = () => {
  const navigate = useNavigate();
  const [bannerLoading, setBannerLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result, loading, error } = getCurrentUserSelector;

  const handleCreateCLBs = () => {
    if (error) return setUserNotLoggedIn(true);
    navigate("/create");
  };

  useEffect(() => {
    const fetchBanner = async () => {
      const { data } = await getAllBanner();
      if (!data) {
        throw new Error("Banner imaged loading...");
      }
      setBannerData(data?.result);
      setBannerLoading(false);
    };
    fetchBanner();
  }, []);

  return (
    <>
      {/* {bannerLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <img src={spinner} alt="spinner" />
        </div>
      ) : (
        bannerData?.length && (
          <Carousel
            indicators
            PrevIcon={<ArrowBackIosNewIcon />}
            NextIcon={<NavigateNextIcon />}
          >
            {bannerData.map((item, i) => (
              <img
                src={item?.banner}
                width={"100%"}
                height={200}
                alt="bannerImage"
                className="carouselImage"
              />
            ))}
          </Carousel>
        )
      )} */}
      <Container>
        <Grid container sx={{ mt: 8, pb: 15 }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Stack sx={{ my: 4 }} spacing={2}>
              <Typography variant="h3" sx={{ color: "#fff" }}>
                Find Exclusive
              </Typography>
              <Typography variant="h3" sx={{ color: "#fff" }}>
                Collection Of
              </Typography>

              <Box className={Style?.startImgsMain}>
                <img src={StartImg1} alt="startImage" />
                <img src={StartImg2} alt="startImage" />
              </Box>

              <Typography variant="h1" className={Style.Text3D}>
                3D CLBs
              </Typography>
              <Typography variant="p" sx={{ color: "#fff" }}>
                Connecting Learning Blocks.
              </Typography>
            </Stack>
            <div className={Style.bigbtn}>
              <Typography
                sx={{ color: "#fff" }}
                onClick={() => navigate("/explore")}
              >
                Explore
              </Typography>
              <Typography
                sx={{ color: "#fff" }}
                onClick={handleCreateCLBs}
                style={{ caretColor: "transparent" }}
              >
                Create
              </Typography>
            </div>
            <div className={Style.details}>
              <div>
                <h1 style={{ color: "#82F9FD" }}>$34 billion</h1>
                <p>24th trading volume on 'Eduthum'</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="75"
                viewBox="0 0 4 75"
                fill="none"
              >
                <path
                  d="M2 1.5V73.5"
                  stroke="#FCFCFC"
                  stroke-opacity="0.5"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
              <div>
                <h1 style={{ color: "#82F9FD" }}>876+</h1>
                <p>Cryptocurrencies Listed</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="75"
                viewBox="0 0 4 75"
                fill="none"
              >
                <path
                  d="M2 1.5V73.5"
                  stroke="#FCFCFC"
                  stroke-opacity="0.5"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
              <div>
                <h1 style={{ color: "#82F9FD" }}>42 million</h1>
                <p>Registered users who trust 'Eduthum'</p>
              </div>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <model-viewer
              style={{ width: "100%", height: 500 }}
              src={modelPath}
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              poster="poster.webp"
              shadow-intensity="1"
              disable
            ></model-viewer>
          </Grid>
        </Grid>
      </Container>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};
export default Landing;
