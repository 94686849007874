import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import { getUserCollection } from "../../service/collection";
import Style from "../../styles/collection.module.scss";
import DividerUI from "../../ui/DividerUI";
import { useDispatch, useSelector } from "react-redux";
import { getUserCollectionAPI } from "../../redux/collection/getUserCollection";
import LoaderUI from "../../ui/LoaderUI";

const Coll = () => {
  const getUserCollectionSelector = useSelector(
    (state) => state?.getUserCollection
  );
  const { result, loading } = getUserCollectionSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserCollectionAPI());
  }, []);

  return (
    <Box className={Style.main}>
      <Header />
      <Stack spacing={2} alignItems="center" sx={{ mx: 2 }}>
        <Typography variant="h4">My Collections</Typography>
        <Typography>
          Create, curate, and manage collections of unique NFTs to share and
          sell.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/collection/create")}
        >
          Create a Collection
        </Button>
      </Stack>
      <DividerUI />
      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          {loading ? (
            <LoaderUI />
          ) : (
            result?.map((item) => {
              return (
                <Grid
                item
                lg={3} 
                md={4} 
                sm={6} 
                xs={12} 
                key={item._id} 
                onClick={() => navigate(`/collection/${item._id}`)}
                >
                  <Card sx={{ bgcolor: "#050505", p: 0 }}>
                  <Box sx={{ width: "100%", height: 200 }}>
                  <img
                      src={item.cover}
                      alt="cover image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "4px 4px 0 0", // Add rounded corners to the top
                      }}
                    />
                    </Box>
                    <Stack
                     sx={{ color: "#fff", mt: 2, p: 2 }} 
                     alignItems="center"
                     spacing={1}
                    >
                      {/* <Avatar
                        src={item.picture}
                        alt="picture"
                        sx={{ width: 70, height: 70 }}
                      /> */}
                     <Avatar
                      src={item.picture}
                      alt="picture"
                      sx={{
                        width: 70,
                        height: 70,
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                        opacity: 0.8,
                        transition: "opacity 0.3s ease",
                        border: "2px solid #fff",
                        borderRadius: "50%",
                        "&:hover": {
                          opacity: 1,
                        },
                      }}
                    />

<Typography variant="h6" align="center" noWrap>
                      {item?.name}
                    </Typography>
                      {/* <Typography variant="p">
                        Rs. {item.price ? item.price : 0}
                      </Typography> */}
                    </Stack>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </Container>
    </Box>
  );
};
export default Coll;

