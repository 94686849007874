import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Stack,
    Box,
} from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { uploadFiles } from "../service/upload";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateProfileAPI } from "../redux/user/updateProfile";
import { getCurrentUserAPI } from "../redux/user/getCurrentUser";

const UploadDocument = ({ country, user }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();

    console.log("check user:", user)

    // Open modal
    const handleUploadDocument = () => setOpenModal(true);

    // Close modal
    const handleClose = () => setOpenModal(false);

    // Handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0]; // Get the first file only
        setSelectedFile(file);
    };

    // Remove uploaded file
    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    // Upload the file (dummy function for now)
    const handleUploadFile = async () => {
        const data = await uploadFiles(selectedFile);
        const fileUrl = data.fileUrl
        // const fileUrl = "https://applore-dev-projects-3.s3.ap-south-1.amazonaws.com/eduthum-assets/nodejs-1-logo-png-transparent.png"

        // console.log("after file upload:", data)
        toast.success("Document uploaded successfully")
        handleClose()
        const response = dispatch(updateProfileAPI({ identityDocument: fileUrl }));
        // console.log("handleUploadFile response:", response)
        setTimeout(() => { dispatch(getCurrentUserAPI()) }, 500)
    };

    return (
        <div>
            {country !== "India" && !user?.identityDocument && !selectedFile ? (
                <Button
                    variant="contained"
                    onClick={handleUploadDocument}
                >
                    Upload Document
                </Button>
            ) : ""}


            {/* Modal for File Upload */}
            <Dialog open={openModal} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Upload Document</DialogTitle>
                <DialogContent>
                    <Box
                        className="dropCard"
                        sx={{
                            p: 2,
                            border: "1px dashed #999",
                            borderRadius: "10px",
                            textAlign: "center",
                            backgroundColor: "black",
                        }}
                    >
                        <input
                            accept="application/pdf,image/jpeg,image/jpg,image/png"
                            style={{ display: "none" }}
                            id="upload-file"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="upload-file">
                            <Stack
                                alignItems="center"
                                spacing={2}
                                sx={{ my: 2 }}
                                style={{ cursor: "pointer" }}
                            >
                                <Typography variant="h6">Upload Your Document!</Typography>
                                <ArrowCircleUpIcon
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        bgcolor: "#999999",
                                        borderRadius: 10,
                                        p: 2,
                                    }}
                                />
                                <Typography variant="subtitle2">JPG, JPEG, PNG</Typography>
                                <Typography variant="subtitle2">Max size: 100 MB</Typography>
                            </Stack>
                        </label>

                        {selectedFile && (
                            <div style={{ marginTop: "10px" }}>
                                <Typography variant="body2">
                                    {selectedFile.name}{" "}
                                    <Button size="small" color="error" onClick={handleRemoveFile}>
                                        Remove
                                    </Button>
                                </Typography>
                            </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions className="bg-gray-700">
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUploadFile}
                        color="primary"
                        disabled={!selectedFile}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UploadDocument;
