import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
 
  const usrToken = localStorage.getItem("accessToken");
  if (
    usrToken == "undefined" ||
    usrToken == "null" ||
    usrToken == "" ||
    !usrToken
  ) {
    return <Navigate to="/signIn" replace />;
  }
  return children;
};
export default ProtectedRoute;
