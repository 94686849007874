import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { moreLikeCLBsAPI } from "../../redux/clb/moreLikeCLBs";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import Style from "../../styles/topBid.module.scss";
import DividerUI from "../../ui/DividerUI";
import { CountdownTimer } from "../../helper/component";
import { useLocation } from "../../LocationContext";

const MoreLikeCLBs = ({ CLB = null }) => {
  const moreLikeCLBsSelector = useSelector((state) => state?.moreLikeCLBs);
  const { result, loading } = moreLikeCLBsSelector;
  const { convertToCurrency } = useLocation();

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult, error: userError } = getCurrentUserSelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const country = localStorage.getItem("countr");
  const currency = localStorage.getItem("currency");
  const userId = localStorage.getItem('userId')
  // console.log("userId===",userId)
  const handleFavirote = async (fav, clbId) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
    dispatch(
      moreLikeCLBsAPI({
        category: CLB?.category?._id,
        isBidAble: CLB?.isBidAble,
        ownerId: userResult?._id,
      })
    );
  };

  useEffect(() => {
    if (!CLB) return;
    dispatch(
      moreLikeCLBsAPI({
        // category: CLB?.category[0]?._id,
        // isBidAble: CLB?.isBidAble,
        ownerId: CLB?.creatorId,
        clbId: CLB?._id,
      })
    );
  }, [CLB]);

  return (
    <>
      <Box sx={{ mt: 10 }} className={Style.main}>
        <Typography align="center" variant="h4">
          More from the Owner
        </Typography>
        <DividerUI />
        <Container>
          <Grid container rowGap={4} spacing={3}>
            {result?.map((item, idx) => {
              console.log("item====",item)
              return (
                <>
                  {item?.isBidAble &&
                  item.endDate >= new Date().toISOString() ? (
                    <Grid item lg={3} md={3} sm={6} xs={12} key={idx}>
                      <Paper className="SingelCLBBox">
                        <Stack
                          alignItems="center"
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ px: 2, zIndex: 100 }}
                          >
                            <Typography>Ending in : </Typography>
                            <CountdownTimer endDate={item.endDate} />
                          </Stack>
                          {item?.favorites || item?.favorites != undefined ? (
                            <IconButton
                              onClick={() => handleFavirote(false, item?._id)}
                              className="likeBtn"
                            >
                              <FavoriteIcon sx={{ color: "primary.main" }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleFavirote(true, item?._id)}
                              className="likeBtn"
                            >
                              <FavoriteBorderIcon
                                sx={{ color: "primary.main" }}
                              />
                            </IconButton>
                          )}
                        </Stack>
                        <Box className="CLBBox">
                          {item?.type == "2d" && (
                            <img src={item?.files?.[0]} width={"100%"} />
                          )}
                          {item?.type == "3d" && (
                            <model-viewer
                              style={{ width: "100%", height: 250 }}
                              src={item?.files?.[0]}
                              ar-modes="webxr scene-viewer quick-look"
                              camera-controls
                              poster="poster.webp"
                              shadow-intensity="1"
                              disable
                            ></model-viewer>
                          )}
                        </Box>
                        <Stack sx={{ p: 2 }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mt: 2 }}
                            alignItems="center"
                          >
                            <Typography variant="p">{item?.title}</Typography>
                            <Typography variant="h6" className={Style?.price}>
                              {/* {currency} {item?.cost?.inr} */}
                              {currency}{" "}
                              {convertToCurrency(item?.cost?.inr)}
                            </Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            {/* {item.isBidAble && ( */}
                            <Button className="gradBtn" variant="contained">
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: "#999999" }}
                                >
                                  Higest Bid
                                </Typography>
                                <Typography variant="p">
                                 {/* {currency} {item?.cost?.inr} */}
                                 {/* {currency}{" "}
                            {currency === "₹"
                              ? item?.cost?.inr
                              : item?.cost?.usd} */}
                              {/* {userId === } */}
                               {currency}{" "}
                               {convertToCurrency(item?.cost?.inr)}
                                </Typography>
                              </Box>
                            </Button>
                            {/* )} */}
                            {item?.isBidAble ? (
                              <Button
                                className={`${Style?.startBidBtn} gradBtn`}
                                variant="contained"
                                onClick={() => navigate(`/explore/${item._id}`)}
                              >
                                Start Bid
                              </Button>
                            ) : (
                              <Button
                                className={`${Style?.startPurchaseBtn} gradBtn`}
                                variant="contained"
                                onClick={() => navigate(`/explore/${item._id}`)}
                              >
                                Purchase
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </Paper>
                    </Grid>
                  ) : (
                    <>
                      {!item?.isBidAble && (
                        <Grid item lg={3} md={3} sm={6} xs={12} key={idx}>
                          <Paper className="SingelCLBBox">
                            <Stack
                              alignItems="center"
                              justifyContent="end"
                              direction="row"
                            >
                              {item?.favorites ||
                              item?.favorites != undefined ? (
                                <IconButton
                                  onClick={() =>
                                    handleFavirote(false, item?._id)
                                  }
                                  className="likeBtn"
                                >
                                  <FavoriteIcon
                                    sx={{ color: "primary.main" }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    handleFavirote(true, item?._id)
                                  }
                                  className="likeBtn"
                                >
                                  <FavoriteBorderIcon
                                    sx={{ color: "primary.main" }}
                                  />
                                </IconButton>
                              )}
                            </Stack>
                            <Box className="CLBBox">
                              {item?.type == "2d" && (
                                <img src={item?.files?.[0]} width={"100%"} />
                              )}
                              {item?.type == "3d" && (
                                <model-viewer
                                  style={{ width: "100%", height: 250 }}
                                  src={item?.files?.[0]}
                                  ar-modes="webxr scene-viewer quick-look"
                                  camera-controls
                                  poster="poster.webp"
                                  shadow-intensity="1"
                                  disable
                                ></model-viewer>
                              )}
                            </Box>
                            <Stack sx={{ p: 2 }}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mt: 2 }}
                                alignItems="center"
                              >
                                <Typography variant="p">
                                  {item?.title}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  className={Style?.price}
                                >
                                  {/* {currency} {item?.cost?.inr} */}
                                  {currency}{" "}
                                  {convertToCurrency(item?.cost?.inr)}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                {item.isBidAble && (
                                  <Button
                                    className="gradBtn"
                                    variant="contained"
                                  >
                                    <Box>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ color: "#999999" }}
                                      >
                                        Higest Bid
                                      </Typography>
                                      <Typography variant="p">
                                        {/* {currency} {item?.cost?.inr} */}
                                        {currency}{" "}
                                        {convertToCurrency(item?.cost?.inr)}
                                      </Typography>
                                    </Box>
                                  </Button>
                                )}
                                {item?.isBidAble ? (
                                  <Button
                                    className={`${Style?.startBidBtn} gradBtn`}
                                    variant="contained"
                                    onClick={() =>
                                      navigate(`/explore/${item._id}`)
                                    }
                                  >
                                    Start Bid
                                  </Button>
                                ) : (
                                  <Button
                                    className={`${Style?.startPurchaseBtn} gradBtn`}
                                    variant="contained"
                                    onClick={() =>
                                      navigate(`/explore/${item._id}`)
                                    }
                                  >
                                    Purchase
                                  </Button>
                                )}
                              </Stack>
                            </Stack>
                          </Paper>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </Grid>
        </Container>
      </Box>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};

export default MoreLikeCLBs;
