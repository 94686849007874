import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [country, setCountry] = useState(localStorage.getItem('country') || '');
  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'USD');
  const [exchangeRate, setExchangeRate] = useState(1); 
  const [loading, setLoading] = useState(false); 

 
  const updateCountry = (newCountry) => {
    console.log("Updating country to:", newCountry);
    const newCurrency = newCountry === "India" ? "₹" : "USD";
    setCountry(newCountry);
    setCurrency(newCurrency);

    localStorage.setItem('country', newCountry);
    localStorage.setItem('currency', newCurrency);
  };

  // Fetch the exchange rate from the API
  const fetchExchangeRate = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://openexchangerates.org/api/latest.json?app_id=f7e886874a7d4ab885688511c4357ac9&base=USD`
      );
      const rate = res?.data?.rates?.INR || 1;
      setExchangeRate(rate);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setLoading(false);
    }
  };

  // Convert USD to INR or vice versa depending on the currency
  const convertToCurrency = (amount) => {
    console.log("converter", amount)
    if (currency === "₹") {
      return amount; // Convert USD to INR
    }
    return (amount / exchangeRate).toFixed(2); // Convert INR to USD
  };

  // Fetch exchange rates on first render or when currency changes
  useEffect(() => {
    fetchExchangeRate();
  }, [currency]);

  return (
    <LocationContext.Provider
      value={{
        country,
        currency,
        setCountry: updateCountry,
        convertToCurrency,
        exchangeRate,
        loading, // Add loading state to manage UI during fetch
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
};
