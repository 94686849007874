import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../assets/png/logoImg1.png";
import Style from "../styles/footer.module.scss";
import { useState } from "react";
import Axios from "../config/authAxios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UserNotLoggedIn from "../common/UserNotLoggedIn";

export default function Footer() {
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result, loading, error } = getCurrentUserSelector;

  const [email, setEmail] = useState();
  const [resource, setResource] = useState();
  const [emailError, setEmailError] = useState();
  const navigate = useNavigate();
  async function submitEmail() {
    if (typeof email === "undefined") {
      setEmailError("Email should not be empty.");
      return;
    }
    const response = await Axios.post(`/subscriber`, {
      email,
    });
    setEmail("");
    // console.log(response);
  }
  async function getResources() {
    const response = await Axios.get(`/resource/get-resource-details`);
    setResource(response.data.result);
  }
  useEffect(() => {
    getResources();
  }, []);

  const handleFooterLinkClick = (e) => {
    e.preventDefault();
    const elementName = e.target.getAttribute("name");
    if (error) {
      setUserNotLoggedIn(true);
      return;
    }
    switch (elementName) {
      case "My Profile":
        navigate(`/profile/${result?._id}`);
        break;

      case "My Collection":
        navigate("/collection");
        break;
      case "Create New CLB":
        navigate("/create");
        break;
      default:
        navigate(`/profile/${result?._id}`);
        break;
    }
  };

  return (
    <Box className={Style.main}>
      <Container>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <a target="_blank" href={resource?.socialMedia.instagram}>
            <IconButton>
              <InstagramIcon sx={{ color: "#fff" }} />
            </IconButton>
          </a>
          <a target="_blank" href={resource?.socialMedia.facebook}>
            <IconButton>
              <FacebookIcon sx={{ color: "#fff" }} />
            </IconButton>{" "}
          </a>
          <a target="_blank" href={resource?.socialMedia.linkedin}>
            <IconButton>
              <LinkedInIcon sx={{ color: "#fff" }} />
            </IconButton>
          </a>
          <a target="_blank" href={resource?.socialMedia.twitter}>
            <IconButton>
              <TwitterIcon sx={{ color: "#fff" }} />
            </IconButton>
          </a>
          {/* <a target="_blank" href={resource?.socialMedia.twitter}>
          <IconButton>
            <WhatsAppIcon sx={{ color: "#fff" }} />
          </IconButton> */}
        </Stack>

        <Grid container spacing={2} className={Style?.footer}>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Box className={Style?.subscribe}>
              {/* {email} */}
              <Stack spacing={2}>
                <TextField
                  name="email"
                  value={email}
                  fullWidth
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  // label="Enter your email address"
                  placeholder="Enter your email address"
                  InputProps={{
                    style: { background: "#c4c4c4", color: "black" },
                  }}
                />
                {emailError && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "-2px",
                    }}
                  >
                    {emailError}
                  </span>
                )}

                <Stack className={Style.buttonField}>
                  <Button
                    sx={{ borderRadius: 10 }}
                    onClick={submitEmail}
                    variant="contained"
                    className={Style.subscribeButton}
                  >
                    Subscribe
                  </Button>
                </Stack>
              </Stack>
            </Box>

            <Box className={Style?.logo}>
              <img
                src={LogoImg}
                alt="logoImage"
                style={{
                  height: "120px",
                  width: "110px",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto",
                  color: "white",
                }}
              />
            </Box>
            <Typography>
              We are India's first NFT buy-sell marketplace where you can buy
              and sell awesome & exclusive NFTs from our impressive collection
              of premium sellers. The best part of NFTSuit is that it has no
              transaction fees.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Grid container sx={{ my: 4 }} spacing={2}>
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Stack spacing={1}>
                  <Link to="/">
                    <Typography variant="h6" fontWeight={600}>
                      Marketplace
                    </Typography>
                  </Link>
                  <Link to="/explore">
                    <Typography variant="subtitle2">All CLBs</Typography>
                  </Link>
                  {/* <Link to="/">
                    <Typography variant="subtitle2">3D CLBs</Typography>
                  </Link>
                  <Link to="/">
                    <Typography variant="subtitle2">2D CLBs</Typography>
                  </Link> */}
                  {/* <Link to="/">
                    <Typography variant="subtitle2">Audio</Typography>
                  </Link>
                  <Link to="/">
                    <Typography variant="subtitle2">Video</Typography>
                  </Link> */}
                </Stack>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Stack spacing={1}>
                  <Typography variant="h6" fontWeight={600}>
                    My Account
                  </Typography>
                  <Link to="/explore">
                    <Typography variant="subtitle2">Explore CLB's</Typography>
                  </Link>
                  <Link>
                    <Typography
                      variant="subtitle2"
                      onClick={handleFooterLinkClick}
                      name="My Profile"
                    >
                      My Profile
                    </Typography>
                  </Link>
                  {/* <Link to="/create">
                    <Typography variant="subtitle2">Favorites</Typography>
                  </Link> */}

                  <Link>
                    <Typography
                      variant="subtitle2"
                      onClick={handleFooterLinkClick}
                      name="My Collection"
                    >
                      My Collection
                    </Typography>
                  </Link>
                  {/* <Link to="/explore">
                    <Typography variant="subtitle2">My Creation</Typography>
                  </Link> */}
                  <Link>
                    <Typography
                      variant="subtitle2"
                      onClick={handleFooterLinkClick}
                      name="Create New CLB"
                    >
                      Create New CLB
                    </Typography>
                  </Link>
                </Stack>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={4}>
                <Stack spacing={1}>
                  <Link to="/explore">
                    <Typography variant="h6" fontWeight={600}>
                      Company
                    </Typography>
                  </Link>
                  <Link to="/aboutUs">
                    <Typography
                      variant="subtitle2"
                    // onClick={() => navigate("/aboutUs")}
                    >
                      About Us
                    </Typography>
                  </Link>
                  <Link to="/faqs">
                    <Typography variant="subtitle2">FAQs</Typography>
                  </Link>
                  <Link to="/gallery">
                    <Typography
                      variant="subtitle2"
                      onClick={() => navigate("/gallery")}
                    >
                      Gallery
                    </Typography>
                  </Link>
                  <Link to="/blogs">
                    <Typography variant="subtitle2">Blogs </Typography>
                  </Link>
                  {/* <Link to="/explore">
                    <Typography variant="subtitle2">Newsletter</Typography>
                  </Link> */}
                  <Link to="/privacy">
                    <Typography
                      variant="subtitle2"
                    // onClick={() => navigate("/privacy")}
                    >
                      Privacy Policy
                    </Typography>
                  </Link>
                  <Link to="/termsCondtion">
                    <Typography
                      variant="subtitle2"
                    // onClick={() => navigate("/termsCondtion")}
                    >
                      Terms & Conditions
                    </Typography>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={true} setOpen={setUserNotLoggedIn} />
      ) : (
        ""
      )}
    </Box>
  );
}
