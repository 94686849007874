import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Style from "../../styles/explore.module.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import { getExploreCLBsAPI } from "../../redux/explore/getExploreCLBs";
import LoaderUI from "../../ui/LoaderUI";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
// import { normalDate } from "../../helper/timer";
import { Pagination } from "@mui/material";
import { CountdownTimer } from "../../helper/component";
import { useLocation } from "../../LocationContext";

export default function ExploreCLBs({ sortFilters, filters, page, setPage }) {
  const [clbs, setClbs] = useState([]);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [cryptoFields, setCryptoFields] = useState({
    walletAddress: "",
    paymentInfo: "",
  });
  const [inrFields, setInrFields] = useState({
    accountNumber: "",
    ifscCode: "",
    bankName: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedCLB, setSelectedCLB] = useState(null);
  const [fetchComplete, setFetchComplete] = useState()

  const getExploreCLBsSelector = useSelector((state) => state?.getExploreCLBs);
  const { result, loading } = getExploreCLBsSelector;

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult, error: userError } = getCurrentUserSelector;

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const country = localStorage.getItem("countr");
  const currency = localStorage.getItem("currency");
  const { convertToCurrency } = useLocation(false);
  // useEffect(() => {
  //   fetchExchangeRate(); // Fetch the exchange rate on component mount
  // }, [fetchExchangeRate]);
  console.log("Country:", country);
  console.log("Currency:", currency);

  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
  const validateFields = () => {
    let validationErrors = {};
    if (selectedPaymentType === "crypto") {
      if (!cryptoFields.walletAddress)
        validationErrors.walletAddress = "Wallet Address is required.";
      if (!cryptoFields.paymentInfo)
        validationErrors.paymentInfo = "Payment Info is required.";
    } else if (selectedPaymentType === "inr") {
      if (!inrFields.accountNumber)
        validationErrors.accountNumber = "Account Number is required.";
      if (!inrFields.ifscCode)
        validationErrors.ifscCode = "IFSC Code is required.";
      if (!inrFields.bankName)
        validationErrors.bankName = "Bank Name is required.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleSubmit = () => {
    if (validateFields()) {
      console.log(selectedPaymentType === "crypto" ? cryptoFields : inrFields);
      // Submit logic here (API call or action dispatch)
      setOpenPaymentPopup(false);
      setCryptoFields({ walletAddress: "", paymentInfo: "" });
      setInrFields({ accountNumber: "", ifscCode: "", bankName: "" });
      setErrors({});
    }
  };

  const handleFavirote = async (fav, clbId, creator) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId, creator }));
    dispatch(
      getExploreCLBsAPI({
        ownerId: userResult?._id,
        ...filters,
        sortFilters,
        page,
      })
    );
  };

  const isExpired = (date) => {
    if (new Date(date).getTime() < new Date().getTime()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const list = [];
    result?.clbs?.map((clb) => {
      if (!isExpired(clb)) list.push(clb);
      return true;
    });
    setClbs(list);
    setFetchComplete(true);
  }, [result]);

  return (
    <>
      {loading ? (
        <LoaderUI />
      ) : clbs?.length > 0 ? (
        <div className="w-full flex py-8 flex-col gap-4">
          <Grid container rowGap={4} spacing={3}>
            {clbs.map((item, idx) => {
              console.log("item===", item);
              return (
                <>
                  <Grid item lg={3} md={3} sm={6} xs={12} key={idx}>
                    <Paper className="SingelCLBBox">
                      <Stack
                        alignItems="center"
                        justifyContent={
                          item?.isBidAble ? "space-between" : "flex-end"
                        }
                        direction="row"
                      >
                        {fetchComplete && item.createdByType === "Admin" && (
                          <label
                            style={{
                              color: "primary.main",
                              fontWeight: "bold",
                              alignItems: "center",
                            }}
                          >
                            {console.log(item.createdByType)}
                            Admin
                          </label>
                        )}

                        {item?.isBidAble && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ px: 2, zIndex: 20 }}
                          >
                            <Typography color="primary">
                              {/* {normalDate(item?.endDate)} */}
                              <CountdownTimer endDate={item?.endDate} />
                            </Typography>
                          </Stack>
                        )}

                        {item?.favorites || item?.favorites != undefined ? (
                          <IconButton
                            onClick={() => {
                              handleFavirote(false, item?._id);
                              // console.log("item=====", item);
                            }}
                            className="likeBtn"
                          >
                            <FavoriteIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              handleFavirote(true, item?._id, item?.creator);
                            }}
                            className="likeBtn"
                          >
                            <FavoriteBorderIcon
                              sx={{ color: "primary.main" }}
                            />
                          </IconButton>
                        )}
                      </Stack>
                      {/* <Box className="CLBBox">
                        {item?.type == "2d" && (
                          <img src={item?.files?.[0]} width={"100%"} />
                        )}
                        {item?.type == "3d" && (
                          <model-viewer
                            style={{ width: "100%", height: 250 }}
                            src={item?.files?.[0]}
                            ar-modes="webxr scene-viewer quick-look"
                            camera-controls
                            poster="poster.webp"
                            shadow-intensity="1"
                            disable
                            loading="lazy"
                          ></model-viewer>
                        )}
                      </Box> */}
                      <Box className="CLBBox">
                        {item?.files?.[0]?.endsWith(".glb") ? (
                          <model-viewer
                            style={{ width: "100%", height: 250 }}
                            src={item?.files?.[0]}
                            ar-modes="webxr scene-viewer quick-look"
                            camera-controls
                            poster="poster.webp"
                            shadow-intensity="1"
                            disable
                            loading="lazy"
                          ></model-viewer>
                        ) : (
                          item?.type == "2d" && (
                            <img
                              src={item?.files?.[0]}
                              width={"100%"}
                              alt="Asset"
                              onClick={() => {
                                setSelectedCLB(item); // Set the selected CLB for the popup
                                setOpenPaymentPopup(true); // Open the payment popup
                              }}
                            />
                          )
                        )}
                      </Box>

                      <Stack sx={{ p: 2 }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ mt: 2 }}
                          alignItems="center"
                        >
                          <Typography variant="p">{item?.title}</Typography>
                          <Typography variant="h6" className={Style?.price}>
                            {/* {currency}{" "}
                            {(currency === "₹"
                              ? item?.cost?.inr ?? 0
                              : item?.cost?.usd ?? 0
                            ).toFixed(2)} */}
                            {currency} {convertToCurrency(item?.cost?.inr)}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          {item?.isBidAble ? (
                            <Button
                              className={"gradBtn"}
                              variant="contained"
                              style={{
                                cursor: "default",
                                pointerEvents: "auto",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "auto",
                                  }}
                                  sx={{ color: "#999999" }}
                                >
                                  Starting Bid Amount
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "auto",
                                  }}
                                >
                                  {/* {currency}
                                  {item?.cost?.inr} */}
                                  {currency}{" "}
                                  {convertToCurrency(item?.cost?.inr)}
                                </Typography>
                              </Box>
                            </Button>
                          ) : (
                            <Button
                              className={"gradBtn"}
                              variant="contained"
                              style={{
                                cursor: "default",
                                pointerEvents: "auto",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "auto",
                                  }}
                                  sx={{ color: "#999999" }}
                                >
                                  Purchase at
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "auto",
                                  }}
                                >
                                  {/* {currency}{" "}
                                  {(currency === "₹"
                                    ? item?.cost?.inr ?? 0
                                    : item?.cost?.usd ?? 0
                                  ).toFixed(2)} */}
                                  {currency}{" "}
                                  {convertToCurrency(item?.cost?.inr)}
                                </Typography>
                              </Box>
                            </Button>
                          )}

                          {item?.isBidAble ? (
                            <Button
                              className={`${Style?.startBidBtn} gradBtn`}
                              variant="contained"
                              onClick={() => navigate(`/explore/${item._id}`)}
                              sx={{
                                color:
                                  new Date(item.endDate).getTime() <
                                  new Date().getTime()
                                    ? "#f50057 !important"
                                    : "inherit",
                              }}
                              disabled={
                                new Date(item.endDate).getTime() <
                                new Date().getTime()
                                  ? true
                                  : false
                              }
                            >
                              {new Date(item.endDate).getTime() <
                              new Date().getTime()
                                ? "Ended"
                                : "Start Bid"}
                              {/* Start Bid */}
                            </Button>
                          ) : (
                            <Button
                              className={`${Style?.startPurchaseBtn} gradBtn`}
                              variant="contained"
                              onClick={() => {
                                if (item?.orderStatus !== "Successfull") {
                                  navigate(`/explore/${item._id}`);
                                }
                              }}
                            >
                              {item?.orderStatus === "Successfull"
                                ? "Sold Out"
                                : "Purchase"}
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </Paper>
                  </Grid>
                </>
              );
            })}
          </Grid>
          <div className="w-full flex justify-end">
            <Pagination
              count={result?.totalPage}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={page}
              sx={{
                ".MuiPagination-root": {
                  color: "white",
                },
                ".MuiPagination-ul": {
                  color: "white",
                },
                ".MuiButtonBase-root": {
                  color: "white",
                },
                ".MuiPaginationItem-ellipsis": {
                  color: "white",
                },
              }}
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Typography variant="h6" className="text-center">
            No CLBs found for this category
          </Typography>
        </div>
      )}
      <Dialog
        open={openPaymentPopup}
        onClose={() => setOpenPaymentPopup(false)}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-paper": {
            padding: 3,
            borderRadius: 2,
            backgroundColor: "#ffffff", // Ensure white background
            color: "#000000", // Black text for better contrast
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#333", // Darker text color for contrast
          }}
        >
          Select Payment Method
        </DialogTitle>
        <DialogContent>
          {!selectedPaymentType ? (
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{ marginTop: 2 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  console.log("Payment Type: Crypto");
                  setSelectedPaymentType("crypto");
                }}
                sx={{
                  padding: 2,
                  fontSize: "1rem",
                }}
              >
                Pay with Crypto
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  console.log("Payment Type: INR");
                  setSelectedPaymentType("inr");
                }}
                sx={{
                  padding: 2,
                  fontSize: "1rem",
                }}
              >
                Pay with INR
              </Button>
            </Stack>
          ) : selectedPaymentType === "crypto" ? (
            <>
              <TextField
                fullWidth
                label="Wallet Address"
                value={cryptoFields.walletAddress}
                onChange={(e) =>
                  setCryptoFields({
                    ...cryptoFields,
                    walletAddress: e.target.value,
                  })
                }
                error={!!errors.walletAddress}
                helperText={errors.walletAddress}
                sx={{
                  mt: 3,
                  "& .MuiInputLabel-root": { color: "#555" }, // Better label contrast
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f9f9f9", // Light field background
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#999", // Hover effect
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2", // Focused effect
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                label="Payment Info"
                value={cryptoFields.paymentInfo}
                onChange={(e) =>
                  setCryptoFields({
                    ...cryptoFields,
                    paymentInfo: e.target.value,
                  })
                }
                error={!!errors.paymentInfo}
                helperText={errors.paymentInfo}
                sx={{
                  mt: 2,
                  "& .MuiInputLabel-root": { color: "#555" },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f9f9f9",
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#999",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                }}
              />
            </>
          ) : (
            <>
              <TextField
                fullWidth
                label="Account Number"
                value={inrFields.accountNumber}
                onChange={(e) =>
                  setInrFields({ ...inrFields, accountNumber: e.target.value })
                }
                error={!!errors.accountNumber}
                helperText={errors.accountNumber}
                sx={{
                  mt: 3,
                  "& .MuiInputLabel-root": { color: "#555" },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f9f9f9",
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#999",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                label="IFSC Code"
                value={inrFields.ifscCode}
                onChange={(e) =>
                  setInrFields({ ...inrFields, ifscCode: e.target.value })
                }
                error={!!errors.ifscCode}
                helperText={errors.ifscCode}
                sx={{
                  mt: 2,
                  "& .MuiInputLabel-root": { color: "#555" },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f9f9f9",
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#999",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                label="Bank Name"
                value={inrFields.bankName}
                onChange={(e) =>
                  setInrFields({ ...inrFields, bankName: e.target.value })
                }
                error={!!errors.bankName}
                helperText={errors.bankName}
                sx={{
                  mt: 2,
                  "& .MuiInputLabel-root": { color: "#555" },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f9f9f9",
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#999",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => {
              console.log("Payment Cancelled");
              setOpenPaymentPopup(false);
              setSelectedPaymentType("");
              setErrors({});
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log("Payment Submitted:", {
                paymentType: selectedPaymentType,
                data:
                  selectedPaymentType === "crypto" ? cryptoFields : inrFields,
              });
              handleSubmit();
            }}
            disabled={Object.keys(errors).length > 0}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
}
