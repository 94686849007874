import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFacebookF, FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import LogoImg from "../../assets/png/logoImg1.png";
import SideImg from "../../assets/png/signInImg1.png";
import {
  signInWithFacebookPopup,
  signInWithGooglePopup,
  signInWithApplePopup,
} from "../../config/firebase";
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { singUpAPI } from "../../redux/auth/singUp";
import {
  default as Style,
  default as styles,
} from "../../styles/auth.module.scss";
import { OutlinedInputUI, TextFieldUI } from "../../theme/muiTheme";
import Snack from "../../ui/Snack";
import toast from "react-hot-toast";
import axios from "axios";
import parseGooglePlace from "parse-google-place";
import { useLocation } from "../../LocationContext";

const schema = yup
  .object({
    email: yup.string().trim().required("Email is required field"),
    password: yup.string().trim().required("Password is required field"),
  })
  .required();

export default function SignIn() {
  const singUpSelector = useSelector((state) => state?.singUp);
  const { message, error } = singUpSelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [location, setLocation] = useState(null);
  const [passwordShow, setPasswordShow] = useState(false);
  const [country, setCountry] = useState("India");
  const { countr, currency, setCountr } = useLocation();

  // const handleSignInWithGoogle = async () => {
  //   const response = await signInWithGooglePopup();
  //   const { user } = response;
  //   // console.log("user=========>>", user);
  //   const signUp = await dispatch(
  //     singUpAPI({ provider: "google", email: user?.email })
  //   );

  //   if (!signUp?.payload?.error) {
  //     localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
  //     setTimeout(() => {
  //       navigate("/");
  //       window.location.reload();
  //     }, 500);
  //   }
  // };
  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleSignInWithGoogle = async () => {
    if (isSigningIn) return;
    setIsSigningIn(true);
  
    try {
      const response = await signInWithGooglePopup();
      const { user } = response;
  
      const signUp = await dispatch(
        singUpAPI({ provider: "google", email: user?.email })
      );
  
      if (!signUp?.payload?.error) {
        localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      if (
        error.code === "auth/cancelled-popup-request" ||
        error.code === "auth/popup-closed-by-user"
      ) {
        // alert("Sign-in was canceled. Please try again.");
      } else {
        console.error("Sign-in failed:", error);
      }
    } finally {
      setIsSigningIn(false);
    }
  };
  
  // Reset state on tab focus
  useEffect(() => {
    const handleTabFocus = () => {
      setIsSigningIn(false);
    };
  
    window.addEventListener("focus", handleTabFocus);
    return () => {
      window.removeEventListener("focus", handleTabFocus);
    };
  }, []);
  const handleSignInWithFacebook = async () => {
    if (isSigningIn) return; // Prevent multiple clicks
    setIsSigningIn(true);
  
    try {
      const response = await signInWithFacebookPopup();
      const { user } = response;
  
      const signUp = await dispatch(
        singUpAPI({ provider: "facebook", email: user?.email })
      );
  
      if (!signUp?.payload?.error) {
        localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      // Handle specific errors for popup closure
      if (
        error.code === "auth/cancelled-popup-request" ||
        error.code === "auth/popup-closed-by-user"
      ) {
        console.warn("Popup closed by user or cancelled, resetting state.");
      } else {
        console.error("Sign-in failed:", error);
      }
    } finally {
      setIsSigningIn(false); // Re-enable the button
    }
  };
  


  // const handleSignInWithFacebook = async () => {
  //   const response = await signInWithFacebookPopup();
  //   const { user } = response;
  //   const signUp = await dispatch(
  //     singUpAPI({ provider: "facebook", email: user?.email })
  //   );

  //   if (!signUp?.payload?.error) {
  //     localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
  //     setTimeout(() => {
  //       navigate("/");
  //       window.location.reload();
  //     }, 500);
  //   }
  // };

  // const handleSignInWithApple = async () => {
  // const response = await signInWithApplePopup();
  // const { user } = response;
  // const signUp = await dispatch(singUpAPI({ provider: "apple", email: user?.email }));

  // if (!signUp?.payload?.error) {
  //   localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
  //   navigate("/");
  // }
  // return null;
  // };

  const handleSignUp = async (data) => {
    const signUp = await dispatch(singUpAPI(data));
    if (!signUp?.payload?.error) {
      localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 500);
    }
  };

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const { latitude, longitude } = position.coords;
  //       setLocation({ latitude, longitude });
  //     });
  //   }
  // }, []);

  useEffect(() => {
  
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("position", position);

        const { latitude, longitude } = position.coords;
        const API_KEY = "YOUR_API_KEY";
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDT1ZNo5GqKA0TaoKgH7oornVHXEaCWxHo`;
        console.log("response",url)
        axios.get(url).then((res) => {
          const address = parseGooglePlace(res.data.results[0]);
   
          console.log("address", address);
          setLocation({ latitude, longitude });
          setCountry(address?.countryLong);
          // setCountry(address?.countryLong)
          const countryLong = address?.countryLong;
          if (countryLong) {
            const newCurrency = countryLong === "India" ? "₹" : "USD";
  
            // Update context states
            setCountry(countryLong);
            // setCurrency(newCurrency);
  
            // Persist in localStorage
            localStorage.setItem('country', countryLong);
            localStorage.setItem('currency', newCurrency);
  
            console.log("Country set to:", countryLong);
            console.log("Currency set to:", newCurrency);
          }
          console.log("Country set to:", address?.countryLong);
        });
      });
    }
  }, [dispatch]);
  return (
    <>
      <Grid container className={Style.login} bgcolor={'black'}>
        <Grid item lg={4} md={4} sm={5} xs={12} className={Style?.signUp}>
          <Stack sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ width: 150 }}>
              <Link to="/">
                {" "}
                <img src={LogoImg} alt="Eduthum" width={"100%"} />
              </Link>
            </Box>
            <Typography variant="h4" sx={{ mt: "1rem", color: "#fff" }}>
              Welcome !
            </Typography>
            <Typography variant="h3" className={Style.signInText}>
              Sign in
            </Typography>
            <form onSubmit={handleSubmit(handleSignUp)}>
              <Stack spacing={2} sx={{ mt: 5 }}>
                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Email <sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your email"
                    inputProps={{
                      style: { color: "#000", borderRadius: "8px" },
                    }}
                    type="email"
                    {...register("email")}
                  />
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Password <sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your password"
                    inputProps={{
                      style: { color: "#000", borderRadius: "8px" },
                    }}
                    type={!passwordShow ? "password" : "text"}
                    endAdornment={
                      !passwordShow ? (
                        <IconButton onClick={() => setPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("password")}
                  />
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                </FormControl>

                <Button variant="contained" type="submit" size="large">
                  Login
                </Button>
              </Stack>
            </form>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Link to="/forget-password">
                <Typography sx={{ color: "#35abaf", mt: "1rem" }}>
                  Forgot password ?
                </Typography>
              </Link>
            </Stack>

            <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography> Don't have an Account ?</Typography>
                <Link to="/signUp">
                  <Typography sx={{ color: "#35abaf" }}>Sign Up</Typography>
                </Link>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography> Sign Up as an Organization ?</Typography>
                <Link to="/orgSignUp">
                  {" "}
                  <Typography sx={{ color: "#35abaf" }}>Sign Up</Typography>
                </Link>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                sx={{ pt: 1, width: "60%" }}
              >
                <FcGoogle
                  onClick={handleSignInWithGoogle}
                  className={styles.direct}
                />
                <FaFacebookF
                  onClick={handleSignInWithFacebook}
                  className={styles.direct}
                  style={{ color: "#3C5A9A" }}
                />
                {/* <FaApple
                  onClick={handleSignInWithApple}
                  className={styles.direct}
                  style={{ color: "#35abaf"}}
                /> */}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12} className={Style?.bgImage}>
          <Box className={Style?.sideImg}>
            <img src={SideImg} alt="backgroundImage" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
