import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CLBEndDateTimer } from "../../helper/timer";
import { getUserCLBsAPI } from "../../redux/clb/getUserCLBs";
import Style from "../../styles/topBid.module.scss";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import Snack from "../../ui/Snack";
import { getfavCLBsAPI } from "../../redux/user/getfavCLBs";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, useGLTF } from "@react-three/drei";

const FavoritesCLBs = () => {
  const getfavCLBsSelector = useSelector((state) => state?.getfavCLBs);
  const { result, loading } = getfavCLBsSelector;

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [CLBFilter, setCLBFilter] = useState("All");

  const handleFavirote = async (fav, clbId) => {
    await dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
    await dispatch(getUserCLBsAPI(id));
    await dispatch(getfavCLBsAPI({ ownerId: id, filter: CLBFilter }));
  };
  const currency = localStorage.getItem("currency");
    // const GLBViewer = ({ url }) => {
    //   const { scene } = useGLTF(url);
    //   return <primitive object={scene} />;
    // };
  const handleFilterCLBs = (filter) => {
    setCLBFilter(filter);
    dispatch(getfavCLBsAPI({ ownerId: id, filter }));
  };

  useEffect(() => {
    dispatch(getfavCLBsAPI({ ownerId: id, filter: CLBFilter }));
  }, []);

  return (
    <>
      <Grid container spacing={3} sx={{ my: 4 }}>
        {result?.map((item, idx) => {
          // console.log("clbs data====",item)
          return (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Paper className="SingelCLBBox">
                <Stack alignItems="flex-end">
                  {item?.favorites && item?.favorites != undefined ? (
                    <IconButton
                      //   onClick={() => handleFavirote(false, item?._id)}
                      onClick={() => handleFavirote(false, item?.clbs[0]?._id)}
                      className="likeBtn"
                    >
                      <FavoriteIcon sx={{ color: "primary.main" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      //   onClick={() => handleFavirote(true, item?._id)}
                      onClick={() => handleFavirote(true, item?.clbs[0]?._id)}
                      className="likeBtn"
                    >
                      <FavoriteBorderIcon sx={{ color: "primary.main" }} />
                    </IconButton>
                  )}
                </Stack>
                <Box className="CLBBox">
                  {item?.clbs[0]?.type == "2d" && (
                    <img src={item?.clbs[0]?.files?.[0]} width={"100%"} />
                  )}
                  {item?.clbs[0]?.type == "3d" && (
                    <model-viewer
                      style={{ width: "100%", height: 250 }}
                      src={item?.clbs[0]?.files?.[0]}
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      poster="poster.webp"
                      shadow-intensity="1"
                      disable
                    ></model-viewer>
                    // <Canvas>
                    //   <ambientLight intensity={0.5} />
                    //   <OrbitControls />
                    //   <GLBViewer url={item?.clbs[0]?.files?.[0]} />
                    // </Canvas>
                  )}
                </Box>
                <Stack sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mt: 2 }}
                    alignItems="center"
                  >
                    <Typography variant="p">{item?.clbs[0]?.title}</Typography>
                    <Typography variant="h6" className={Style?.price}>
                      {/* ₹ {item?.clbs[0]?.cost?.inr} */}
                      {currency}{" "}
                      {currency === "₹"
                        ? item?.clbs[0]?.cost?.inr
                        : item?.clbs[0]?.cost?.usd}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Button className="gradBtn" variant="contained">
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#999999" }}
                        >
                          Higest Bid
                        </Typography>
                        <Typography variant="p">
                          {/* ₹ {item?.clbs[0]?.cost?.inr} */}
                          {currency}{" "}
                          {currency === "₹"
                            ? item?.clbs[0]?.cost?.inr
                            : item?.clbs[0]?.cost?.usd}
                        </Typography>
                      </Box>
                    </Button>
                    {item?.clbs[0]?.isBidAble ? (
                      <Button
                        className={`${Style?.startBidBtn} gradBtn`}
                        variant="contained"
                        onClick={() =>
                          navigate(`/explore/${item.clbs[0]?._id}`)
                        }
                      >
                        Start Bid
                      </Button>
                    ) : (
                      <Button
                        className={`${Style?.startPurchaseBtn} gradBtn`}
                        variant="contained"
                        onClick={() =>
                          navigate(`/explore/${item.clbs[0]?._id}`)
                        }
                      >
                        Purchase
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default FavoritesCLBs;
