import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { assignClbAPI } from "../../redux/clb/assignClb";
import { getAllOrdersAPI } from "../../redux/orders/getAllOrders";
import styles from "../../styles/order.module.scss";

const loggedInUserEmail = localStorage.getItem('userEmail');


const schema = yup
  .object({
    name: yup.string().required("Name is required field"),
    email: yup
      .string()
      .required("Email is a required field")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Please enter a valid email address"
      ).test(
        'is-not-stored-email',
        "You can't assign to yourself",
        (value) => value !== loggedInUserEmail // Custom validation: Check if the email matches the one from localStorage
      ),

    password: yup.string().required('Password is a required field'),
    // blockType: yup.string().required(),
    // action: yup.string().required(),
  })
  .required();

function AssignClbModal({ open, setOpen, clbId }) {
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const {
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
  });

  //   const [permissions, setPermissions] = useState([]);

  //   const handleEditNewPermissionModule = async (data) => {
  //     if (!permissions?.length) {
  //       return setError("permssionsError", {
  //         type: "custom",
  //         message: "Permission can not be empty!",
  //       });
  //     }
  //     await dispatch(
  //       editPermissionModuleAPI({ permissionId, permissions, name: data?.name })
  //     );
  //     await dispatch(getAllPermissionsAPI());
  //     setOpen(false);
  //   };

  console.log(clbId);

  const handleGeneratePassword = () => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()-_=+";

    const allChars = lowercaseChars + uppercaseChars + numbers + specialChars;

    let generatedPassword = "";
    let i = 0;

    generatedPassword +=
      lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    generatedPassword +=
      uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    generatedPassword += numbers[Math.floor(Math.random() * numbers.length)];
    generatedPassword +=
      specialChars[Math.floor(Math.random() * specialChars.length)];

    while (i < 10) {
      generatedPassword +=
        allChars[Math.floor(Math.random() * allChars.length)];
      i++;
    }

    generatedPassword = generatedPassword
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    setPassword(generatedPassword);
    setValue('password', generatedPassword)
    clearErrors('password')
  };

  const handleClose = () => setOpen(false);

  const
    handleSubmitSubmittedData = async (data) => {
      const formData = {
        ...data,
        password,
        blockType: "clb",
        action: "create",
        clbId,
      };

      const assigned = await dispatch(assignClbAPI(formData));
      await dispatch(getAllOrdersAPI("All"));

      if (assigned) {
        handleClose();
      }
    };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <form onSubmit={handleSubmit(handleSubmitSubmittedData)}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  Enter Details to Assign CLB
                </Typography>
                <TextField
                  label="Name"
                  // sx={{width:"100%"}}
                  {...register("name")}
                />
                {errors?.name?.message && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: '8px' }}
                  >
                    {errors.name.message}
                  </Typography>
                )}
                <TextField
                  label="Email"
                  {...register("email")}
                  // error={errors?.email?.message}
                  // helperText={errors?.email?.message}
                  {...register("email", {
                    required: "Email is required",
                    validate: value => value !== loggedInUserEmail || "You can't assign to yourself"
                  })}
                />
                {errors?.email?.message && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: '8px' }}
                  >
                    {errors.email.message}
                  </Typography>
                )}



                <Stack direction="row" spacing={2}>

                  <div>
                    <TextField
                      label="Password"
                      value={password}
                      disabled
                      className={styles.passwordField}
                      {...register("password")}
                    // error={errors?.password?.message}
                    // helperText={errors?.password?.message}
                    />
                    {errors?.password?.message && (
                      <Typography
                        variant="body2"
                        color="error"
                        style={{ marginTop: '8px' }}
                      >
                        {errors.password.message}
                      </Typography>
                    )}

                  </div>
                  <Button
                    onClick={handleGeneratePassword}
                    variant="contained"
                    color="primary"
                  >
                    Generate Password
                  </Button>
                </Stack>

                {/* <TextField
                  label="Password"
                  value={password}
                  disabled
                  //   {...register("password")}
                  //   error={errors?.name?.message}
                  //   helperText={errors?.name?.message}
                /> */}
                {/* <Autocomplete
                  multiple
                  disablePortal
                  defaultValue={selectedPermission?.permissions}
                  options={result?.adminPermissions}
                  fullWidth
                  onChange={(e, value) => setPermissions(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Permissions" />
                  )}
                /> */}
                <FormHelperText
                // error={errors?.permssionsError?.message}
                >
                  {/* {errors?.permssionsError?.message} */}
                </FormHelperText>

                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Assign
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AssignClbModal;
