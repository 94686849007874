import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, IconButton, Modal, Stack, Typography } from "@mui/material";
import { PeraWalletConnect } from "@perawallet/connect";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import coingate from "../assets/png/coingate.png";
import paraLogo from "../assets/png/paraLogo.png";
import RazorpayLogo from "../assets/svg/RazorpayLogo.svg";
import { placeOrderAPI } from "../redux/orders/placeOrder";
import rzPayHandler from "../service/RazorpayIntegration";
import HttpService from "../../src/config/authAxios";
import toast from "react-hot-toast";
import { useSDK } from "@metamask/sdk-react";
import { placeMultipleOrdersAPI } from "../redux/orders/placeMultipleOrders";

export default function PayGasFees({ open, setOpen, gasPrice }) {
  const buyCLBSelector = useSelector((state) => state?.buyCLB);
  const { message, error } = buyCLBSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const [account, setAccount] = useState("");
  const connect = async () => {
    let address;

    try {
      const accounts = await sdk?.connect();
      console.log(accounts);
      address = accounts?.[0];
      setAccount(accounts?.[0]);
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
    return address;
  };

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: user } = getCurrentUserSelector;

  const handleClose = () => setOpen(false);

  const handleParaPay = () => {
    const peraWallet = new PeraWalletConnect({ chainId: "416001" });
    peraWallet.connect();
  };

  const handleCoingate = async () => {
    const paymentObject = {
      price_amount: gasPrice,
      price_currency: "usd",
      receive_currency: "eth",
      success_url: window.location.href,
      cancel_url: window.location.href,
    };

    try {
      const response = await HttpService.post(
        "/payments/create-payment",
        paymentObject
      );
      if (response.data && response.data.payment_url) {
        localStorage.setItem("Coingate-gas-priceId", response?.data?.id);
        localStorage.setItem("Coingate-gas-price", gasPrice);
        toast.success("Redirecting to payment page", {
          duration: 5000,
          position: "bottom-left",
        });

        window.location.href = response.data.payment_url;
      } else {
        toast.error("Payment creation failed.", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Card className="alertBox">
          <Stack direction="row" sx={{ my: 2 }}>
            <IconButton style={{ userSelect: "none", pointerEvents: "none", opacity: "0" }}>
              <CloseIcon />
            </IconButton>
            <Typography align="center" variant="h5" sx={{ flex: 1 }}>
              Pay Gas Fees! 
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography>
                {" "}
                Are you sure you want to pay gas fees. You will be getting
                confirmation notification!
              </Typography>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
              {/* <Box
                sx={{ bgcolor: "#fff", width: 150, p: 2 }}
                onClick={handleRzPay}
              >
                <img width={"100%"} src={RazorpayLogo} alt="razorpay" />
              </Box> */}
              {/* <Box
                sx={{ bgcolor: "#fff", width: 150, p: 2 }}
                onClick={handleParaPay}
              >
                <img width={"100%"} src={paraLogo} alt="para wallet" />
              </Box> */}
              <Box
                sx={{ bgcolor: "black", width: 150, p: 2 }}
                onClick={handleCoingate}
              >
                <img width={"100%"} src={coingate} alt="para wallet" />
              </Box>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}
